import { KONVA_FACET_GROUP } from '../../../constants';

// Mapping of groupnames
const KonvaGroupNames = {
	/**Groups**/
	existingFacetsGroup: KONVA_FACET_GROUP.EXISTING_GROUP_NAME,
	existingPanelsGroup: `${KONVA_FACET_GROUP.EXISTING_GROUP_NAME}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}${KONVA_FACET_GROUP.PANEL_GROUP_SUFFIX}`,
	obstructionsGroup: 'obstructions',
	existingFacetsSetbackAreaGroup: 'setback-area',
	inProgressFacetAzimuthSelectorGroup: `${KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}${KONVA_FACET_GROUP.AZIMUTH_SELECTOR_SUFFIX}`,
	facetDrawingGroup: 'panel-drawer',
	inProgressUserDefinedFacetGroup: KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME,
	
	/**Lines**/
	existingFacet: `${KONVA_FACET_GROUP.EXISTING_GROUP_NAME}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}facet`
};

// Generate the panel group ID for a given roof segment
function getExistingPanelsGroupID(roofSegmentID: string) {
	return `${roofSegmentID}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}${KonvaGroupNames.existingPanelsGroup}`;
}

function getExistingFacetsGroupID(roofSegmentID: string) {
	return `${roofSegmentID}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}${KONVA_FACET_GROUP.EXISTING_GROUP_NAME}`;
}

function getRoofSegmentIDFromGroupID(groupID: string) {
	return groupID.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];
}

export {
	KonvaGroupNames,
	getExistingPanelsGroupID,
	getExistingFacetsGroupID,
	getRoofSegmentIDFromGroupID
};

