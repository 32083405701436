import styled, { } from 'styled-components';

export const ZoombuttonWrap = styled.div` 
    border-radius: 3px;
    position: relative; 
    box-shadow: 0px 0.5px 1.5px #0000001a;
    background: #fff;
    &:before{
      content: '';
      position: absolute;
      height: 1px;
      width: 20px;
      left: 9px;
      top: 38px;
      background: #e5e5e5;
    }


    .zoombtnHover{
        font-size: 0.938rem;
        font-weight: 600;
        background: transparent;
        border: transparent;
        border-radius:0;
        color: #666666;
        padding: 0.25rem;
        display: flex;
        align-items-center;
        justify-content:center;
        height:38px;
        width:38px;
    }
`;