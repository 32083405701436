import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo } from './style';
import { useDispatch, useSelector } from 'react-redux';

import { toggleResetChangesLayoutModalSliceState } from 'store/slices/ResetChangesSlice';
import { RootState } from 'store';
import { toggleManualToolDoneButtonState, toggleManualToolModalState, toggleManualToolResetButtonState } from 'store/slices/ManualToolModal';
import { useRefs } from 'contexts/RefContext';
import Konva from 'konva';
import { setRoofIndexes } from 'store/slices/ToolSlice';
import { getRoofSegmentIDFromGroupID, KonvaGroupNames } from 'components/tool/utils';

export default function ResetChangesModal() {
	const { open, warningMessage, combinedObjsForDefaultMode, combinedObjsForNonDefaultMode } = useSelector(
		(state: RootState) => state.resetChangesModal.data
	);
	const { roofIndexes} = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();
	const { konvaRef } = useRefs();

	function disbaleEditMode() {
		dispatch(setRoofIndexes({ roofIndexes: [] }));
	}

	function resetToLoadedState() {
		const facetDetails: { [key: string]: { previousPitch: number, previousShading?: number, previousAzimuth: number } } = {};
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		combinedObjsForNonDefaultMode!.forEach((facet:facetDetail) => {
			if ('previousPitch' in facet && facet.previousPitch !== undefined &&
				'previousShading' in facet && facet.previousShading !== undefined &&
				'azimuthDegrees' in facet && facet.azimuthDegrees !== undefined) {

				facetDetails[facet.konvaPolygonId] = {
					previousPitch: facet.previousPitch,
					previousShading: facet.previousShading,
					previousAzimuth: facet.azimuthDegrees,
				};
			}
		});
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		combinedObjsForDefaultMode!.forEach((facet:facetDetail) => {
			if ('previousPitch' in facet && facet.previousPitch !== undefined &&
				'azimuthDegrees' in facet && facet.azimuthDegrees !== undefined) {

				facetDetails[facet.konvaPolygonId] = {
					previousPitch: facet.previousPitch,
					previousAzimuth: facet.azimuthDegrees,
				};
			}
		});
		console.log(facetDetails);
		const groups = konvaRef?.current?.find(`.${KonvaGroupNames.existingPanelsGroup}`).
			filter(g => roofIndexes.includes(getRoofSegmentIDFromGroupID(g.attrs.id))) as Konva.Group[];
		groups?.forEach((g) => {
			const facetKey = getRoofSegmentIDFromGroupID(g.attrs.id);
			const prevVals = facetDetails[facetKey];
			if (prevVals) {
				g.setAttr('pitch', prevVals.previousPitch);
				g.setAttr('shading', prevVals.previousShading);
				g.setAttr('azimuth', prevVals.previousAzimuth);

				// Set rotation
				if (g.rotation()) {
					g.rotation(prevVals.previousAzimuth);
					g.position({ x: 0, y: 0 });
					g.skew({ x: 0, y: 0 });
				}
			}
		});
		disbaleEditMode();
		dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: false }));
		dispatch(toggleManualToolResetButtonState({ resetEnabled: false }));
		dispatch(toggleManualToolModalState({ value: false }));
	}

	function hanldeConfirmation() {
		resetToLoadedState();
		dispatch(toggleResetChangesLayoutModalSliceState({
			open: false,
			warningMessage: '',
		}));
	}

	const closeModal = () => {
		dispatch(toggleManualToolModalState({ value: true, showAll: true }));
		dispatch(toggleResetChangesLayoutModalSliceState({
			open: false,
			warningMessage: '',
		}));
	};

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open) closeModal();
			}}
		>
			<ModalPopup>
				<ModalcontentInfo>
					{warningMessage}
				</ModalcontentInfo>
				<ModalbuttonWrap>
					<Modalbutton onClick={() => hanldeConfirmation()}>
											Yes
					</Modalbutton>
					<ModalbuttonNo onClick={() => closeModal()}>
											No
					</ModalbuttonNo>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}
