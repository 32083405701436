import { memo, useMemo } from 'react';
import { PANEL_OFF_COLOR, STROKE_COLOR_FOR_TEMP_ADDED_PANEL } from '../../../constants';
import { Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { resizePoints } from '../utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

function UserDefinedTemporaryPanels({ roofSegmentId }: { roofSegmentId: string }) {
	const newlyAddedPanels = useSelector((state: RootState) => state.panelSlice.data.newlyAddedPanels);
	const scale = useKonvaImageScale();

	const transformNewlyAddedPanel = useMemo(() => {
		return newlyAddedPanels.filter((p) => p.roofSegmentId === roofSegmentId)
			.map((p) => ({ ...p, exteriorCoords: resizePoints(p.exteriorCoords.flat(), scale) }));
	}, [newlyAddedPanels, roofSegmentId, scale]);

	if (!transformNewlyAddedPanel.length) return null;

	return (
		<>
			{
				transformNewlyAddedPanel.map((p, index) => (
					<Line
						key={`${p.roofSegmentId}${index}`}
						stroke={STROKE_COLOR_FOR_TEMP_ADDED_PANEL}
						closed={true}
						strokeWidth={1.4}
						points={p.exteriorCoords.flat()}
						fill={PANEL_OFF_COLOR}
						name={`panel-${p.roofSegmentId}`}
					/>

				))
			}
		</>
	);
}

export default memo(UserDefinedTemporaryPanels);