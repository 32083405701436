import { useDispatch, useSelector } from 'react-redux';
import { setIrrandiance } from 'store/slices/IrradianceSlice';
import { RootState } from 'store';
import { IrradianceState } from 'store/slices/IrradianceSlice/type';
import { MonthButtonsWrap } from './style';
import { debounce } from '../../../util';
import Button from 'components/Basic/Button';
import { MONTHS } from '../../../constants';

export default function MonthButtons() {
	const { selectedMonth, irradianceImages, showIrradiance } = useSelector((state: RootState) => state.Irradiance.data as IrradianceState);
	const { threeDModelEnabled, fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const imagerySource = useSelector((state: RootState) => state.roofData.data.imagerySource);
	const dispatch = useDispatch();
	const deboubceToggle = debounce((selectedMonth: number) => dispatch(setIrrandiance({ selectedMonth })), 10);

	if (!showIrradiance || !irradianceImages[imagerySource]?.length || threeDModelEnabled) {
		return null;
	}

	return (
		<MonthButtonsWrap fullScreenEnabled={fullScreenEnabled} >
			{
				MONTHS.map((month, index: number) => (
					<Button className="months-btn"
						style={{
							background: `${selectedMonth === index ? '#003CFF' : '#fff'}`,
							border: `${selectedMonth === index ? '1px solid #003CFF' : '1px solid #9C9CA1'}`,
							color: `${selectedMonth === index ? '#fff' : '#5F636B'}`,
						}}
						onClick={() => deboubceToggle(index)}
						key={month}>
						{month}
					</Button>
				))
			}
		</MonthButtonsWrap>

	);
}