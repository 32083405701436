import React, { InputHTMLAttributes, forwardRef } from 'react';
import { ToolbarLabel } from 'components/tool/Editor/style';
import { ToggleBtnWrap } from './style';
interface Props extends InputHTMLAttributes<HTMLInputElement> {
	title: string,
	disabled: boolean,
	checked: boolean,
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

const CustomToggle = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const { title, disabled, checked, onChange } = props;
	return (
		<ToggleBtnWrap className='switchButton bg-transparent switchButton-custom '>
			<ToolbarLabel className='toolbar-label'>{title}</ToolbarLabel>
			<div className="toggle-button-cover">
				<div className="button-cover">
					<div className="button b2" id="button-13">
						<input
							checked={checked}
							ref={ref}
							type="checkbox"
							className="checkbox"
							disabled={disabled}
							onChange={onChange}
						/>
						<div className="knobs">
							<span></span>
						</div>
						<div className="layer"></div>
					</div>
				</div>
			</div>
		</ToggleBtnWrap>
	);
});

CustomToggle.displayName = 'CustomToggle';
export default CustomToggle;
