import { translatePanels } from 'components/ManualToolModal/api';
import { getAbsolutePointsOfTranslatedPanels } from 'components/ManualToolModal/util';
import { useRefs } from 'contexts/RefContext';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

export default function useTranslatePanelPositionsMutation() {
	const { panel, uuid, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { roofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const scale = useKonvaImageScale();
	const { konvaRef } = useRefs();

	const translatePanelPositionsMutation = useMutation(
		async () => {
			if (!konvaRef?.current) return;
			const newTranslatedPositions = getAbsolutePointsOfTranslatedPanels(konvaRef.current, roofIndexes, allRoofSegs, scale);
			if (!newTranslatedPositions.length) throw new Error('E_NO_TRANSLATIONS_TO_SAVE');
			await translatePanels(newTranslatedPositions, panel.key, uuid);
		},
		{
			mutationKey: 'translate-panels',
			onError(error: AxiosError) {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE') return;
				toast.error('Something went wrong, please try again');
			},
		});

	return translatePanelPositionsMutation;
}
