import Button from 'components/Basic/Button';
import Konva from 'konva';
import { useRefs } from 'contexts/RefContext';
import { getRoofSegmentIDFromGroupID, KonvaGroupNames } from 'components/tool/utils';
import { useCallback, useEffect, useState } from 'react';
import { removeNewlyCreatedFacets } from 'store/slices/ToolSlice';
import { useDispatch } from 'react-redux';

export default function UndoButton() {

	const { konvaRef } = useRefs();
	const [undoEnabled, setUndoEnabled] = useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const stage = konvaRef?.current;
		if (!stage) return;
		const panelDrawerGroup = stage.findOne(`.${KonvaGroupNames.facetDrawingGroup}`) as Konva.Group;
		if (!panelDrawerGroup) return;
	
		const handleAdd = () => {
			const isDrawing = (panelDrawerGroup?.children?.length || 0) > 1;
			setUndoEnabled(isDrawing);
		};
		panelDrawerGroup.on('add', handleAdd);
	
		return () => {
			panelDrawerGroup.off('add', handleAdd);
		};
	}, [konvaRef]);

	const undoFacetLines = useCallback(() => {
		if (!konvaRef?.current) return;

		const panelDrawerGroup = konvaRef.current.findOne(`.${KonvaGroupNames.facetDrawingGroup}`) as Konva.Group;
		if (!panelDrawerGroup?.hasChildren()) return;

		const allInProcessGroups = panelDrawerGroup.find(`.${KonvaGroupNames.inProgressUserDefinedFacetGroup}`) as Konva.Group[];
		if (!allInProcessGroups.length) return;

		const recentGroup = allInProcessGroups[allInProcessGroups.length -1] as Konva.Group;
		if (!recentGroup.children?.length) return;

		const children = recentGroup.children;
		const vertices = recentGroup.find('Circle') as Konva.Circle[];
		const previewLine = recentGroup.findOne('.preview-line') as Konva.Line;
		previewLine?.points([]);
		const facetLine = children[0] as Konva.Line;
		if (facetLine) {
			if (facetLine?.closed()) {
				dispatch(removeNewlyCreatedFacets({ id: getRoofSegmentIDFromGroupID(facetLine.id()) }));
				recentGroup.destroy();
			}

			const points = facetLine.points();
			if (points.length >= 2) {
				points.splice(-2);
				vertices.pop()?.destroy();
				facetLine.points(points);
				facetLine.closed(false);
			} 

			if(!points.length) {
				recentGroup.destroy();
			}
		}

		const hasChildren = (panelDrawerGroup?.children?.length || 0) > 1;
		setUndoEnabled(hasChildren);
	},[konvaRef]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if ((event.ctrlKey && event.key === 'z') || event.key === 'Escape') {
				undoFacetLines();
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [undoFacetLines]);

	return (
		undoEnabled ?
			<>
				<Button
					className="facetundo active"
					id="undo"
					onClick={()=>undoFacetLines()}
				>
					<svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19 5.15385H7.92308C6.08696 5.15385 4.32605 5.88324 3.02772 7.18157C1.72939 8.4799 1 10.2408 1 12.0769C1 
						13.913 1.72939 15.6739 3.02772 16.9723C4.32605 18.2706 6.08696 19 7.92308 19H13.4615M19 
						5.15385L14.8462 1M19 5.15385L14.8462 9.30769" 
						stroke="#64686A" 
						strokeWidth="1.6" 
						strokeLinecap="round" 
						strokeLinejoin="round"/>
					</svg>
				</Button>
			</>
			: null
	);

}