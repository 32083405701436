import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import CustomToggle from 'components/Basic/CustomToggle/CustomToggle';
import { showAllPanels as showAllPanelsAction } from 'store/slices/RoofDataSlice/roofDataSlice';
import { debounce } from '../../../util';
import { FlexDivRow } from '../style';

export default memo(function AllPanelsToggle() {
	const { org, showAllPanels } = useSelector((state: RootState) => state.roofData.data);

	const dispatch = useDispatch<AppDispatch>();

	const toggleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		debouncedToggleHandler(event.target.checked);
	};

	const debouncedToggleHandler = debounce((toggleValue: boolean) => {
		dispatch(showAllPanelsAction({ toggleValue }));
	}, 250);

	return (
		<FlexDivRow className={` ${!org.setting?.isB2C ? 'panels-modes-wrap': 'panels-wrap' }`}>
			<CustomToggle
				title='Show all panels'
				checked={showAllPanels}
				defaultChecked={showAllPanels}
				onChange={toggleChecked}
				disabled={false}
			/>
		</FlexDivRow>
	);
});
