import Button from 'components/Basic/Button';
import { deleteAllFacetsFromPanelDrawer } from 'components/tool/utils';
import { useRefs } from 'contexts/RefContext';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setThreeDState } from 'store/slices/ToolSlice';
export default function Show3DButton () {
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	// const { editModeEnabled, fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	
	const dispatch = useDispatch<AppDispatch>();
	const [loading, setLoading] = useState(false);
	const {konvaRef} = useRefs();
	const handleClick = () => {
		if (!loading) {
			setLoading(true);
			if(!threeDModelEnabled && konvaRef?.current){
				deleteAllFacetsFromPanelDrawer(konvaRef?.current);
			}
		
			dispatch(setThreeDState({ enabled: !threeDModelEnabled }));
			setTimeout(() => {
				setLoading(false);
			}, 500);
		}
	};
	return (
		<Button style={{
			background: `${threeDModelEnabled ? 'var(--secondary)' : '#fff'}`,
			borderColor: `${threeDModelEnabled ? 'var(--secondary)' : '#C7CACF'}`,
			color: threeDModelEnabled? '#28373E':'#64686A',
		}} 
		onClick={handleClick} className={'btnFont0 btnHover btn-3d ' + `${threeDModelEnabled ? ' active' : ''}`}>
			<svg style={{display: 'flex',flexShrink: '0'}} width="21.04" height="20" viewBox="0 0 20 19" 
				fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17.0804 8.84263V6.94495C17.0817 6.48941 16.9609 6.04184 16.7306 5.64878C16.5004 5.25572 16.169 
				4.93151 15.771 4.70986L10.6697 1.8779C10.2903 1.66658 9.86318 1.55566 9.42887 1.55566C8.99456 1.55566 8.56745 
				1.66658 8.18802 1.8779L3.08672 4.70901C2.68861 4.93073 2.35718 5.25507 2.12691 5.6483C1.89664 6.04152 1.77594 
				6.48926 1.77736 6.94495V12.4376C1.77609 12.8931 1.89686 13.3407 2.12713 13.7338C2.35739 14.1268 2.68874 14.451 
				3.08672 14.6727L8.18802 17.5046C8.56745 17.716 8.99456 17.8269 9.42887 17.8269C9.86318 17.8269 10.2903 17.716 
				10.6697 17.5046L12.8295 16.3057" stroke={threeDModelEnabled? '#28373E':'#64686A'} 
				strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M16.5637 5.40576L9.4303 9.69095M9.4303 9.69095L2.29688 5.40576M9.4303 9.69095V17.8177" 
					stroke={threeDModelEnabled? '#28373E':'#64686A'} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M16.5601 15.5799C17.6838 15.5799 18.5948 14.6689 18.5948 13.5452C18.5948 12.4215 17.6838 11.5105 16.5601 
				11.5105C15.4364 11.5105 14.5254 12.4215 14.5254 13.5452C14.5254 14.6689 15.4364 15.5799 16.5601 15.5799Z" 
				stroke={threeDModelEnabled? '#28373E':'#64686A'} strokeWidth="1.6"/>
			</svg>
			<span>3D</span>
		</Button>
	);
}