import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { redoPanelState, undoPanelState } from 'store/slices/PanelSlice';
import { resizePoints } from '../utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

export default function SimplifiedConcaveHullLines(){
	const [showLine, setShowLine] = useState(false);
	const { allRoofSegs} = useSelector((state: RootState) => state.roofData.data);
	const scale= useKonvaImageScale();
	const dispatch = useDispatch();

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if(event.ctrlKey && event.key === 'z') {
				dispatch(undoPanelState());
			} else if(event.ctrlKey && event.key === 'y') {
				dispatch(redoPanelState());
			} else if ((event.key === 'l' || event.key === 'L')) {
				event.preventDefault();
				setShowLine(true);
			}
		};
		
		const handleKeyUp = (event: KeyboardEvent) => {
			setShowLine(false);
		};

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [dispatch]);

	const transformedRoofSegments = useMemo(() => (
		allRoofSegs.map(segment => {
			const transformedPoints = resizePoints(segment.simplifiedHullCoords.flat(), scale);
			return {
				points: transformedPoints,
				id: segment.id,
			};
		})), [allRoofSegs, scale]);

	if(!showLine) {
		return null;
	}

	return (
		<>
			{
				transformedRoofSegments.map(eachSeg => ( 
					(<Line
						key={eachSeg.id}
						stroke={'Red'}
						strokeWidth={2}
						points={eachSeg.points}
					/>))
				)
			}
		</>
	);
}