import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { debounce } from '../../../util';
import { setIrrandiance } from 'store/slices/IrradianceSlice';
import {
	PanelCheckbox,
	PanelCheckboxWrap,
	PanelCheckboxSvg
} from './style';
export default function PanelToggle() {
	const dispatch = useDispatch();
	const { showPanels } = useSelector((state: RootState) => state.Irradiance.data);
	const { editModeEnabled, threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const deboubceToggle = debounce((showPanels: boolean) => dispatch(setIrrandiance({ showPanels })), 10);

	if(threeDModelEnabled ){
		return null;
	}

	return <PanelCheckbox>
		<PanelCheckboxWrap>
			<PanelCheckboxSvg 
				style={{background:`${showPanels ?'var(--primary)':'#fff'}`,
					border: `${showPanels ? '1px solid var(--primary)' : '1px solid  #C7CACF'}`,
				}}>
				<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 5L4.5 8L10.5 1" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				<input disabled={false} checked={showPanels} type="checkbox" className="checkbox" 
					onChange={(evt) => deboubceToggle(evt.target.checked)}/>
			</PanelCheckboxSvg>
			<label>Panels On</label>
		</PanelCheckboxWrap>
	</PanelCheckbox>;
}