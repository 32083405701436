import styled, { } from 'styled-components';

export const PanelOrientationWrap = styled.div`
			display: flex;
			align-items: center;
    	pointer-events: all;
			overflow: hidden;
			// border: 1px solid rgb(228,228,228);
			// border-radius: 91.4939px;
			// position: absolute;
			// top: 40px;
    	// left: 50px;
`;

export const LabelOrientation = styled.label`
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 0px;
	border-left: 0px;
	border-right: 1px solid #E4E4E4;
	color: #28373E;
	font-size: 0.938rem;
	padding: 0px .65rem;
	background: #fff;
	height: 30px;
	width: auto;
	border-radius: 0px;
	min-width: 40px;

	&#multiple-panel{
		padding-left: 0.25rem;
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

`;
