import { Polygon, Response, testPolygonPolygon, Vector } from 'sat';
import Konva from 'konva';
import { Geom, intersection } from 'polygon-clipping';
import { FACET_HIGHLIGHT_COLOR , FACET_HIGHLIGHT_OFF_COLOR} from '../../constants';

// Helper to convert degrees to radians
function degToRad(deg: number) {
	return (deg * Math.PI) / 180;
}

export function getSnappedPosition(
	position: { x: number; y: number },
	currentShape: any,
	siblings: Konva.Node[]
) {
	const snapThreshold = 10; // Define the snapping threshold (adjust this as needed)
	let closestSnapPoint: any = null;
	let closestDistance = Infinity;

	// Convert current shape to an SAT polygon (with the new position)
	const currentPolygon = convertToSATPolygon(currentShape, position);

	// Iterate through sibling shapes
	siblings.forEach((sibling) => {
		if (!(sibling instanceof Konva.Line)) return;

		// Convert sibling shape to SAT polygon
		const siblingPos = sibling.position();
		const siblingPolygon = convertToSATPolygon(sibling, siblingPos);

		// Check for collision using SAT.js
		const response = new Response();
		const collided = testPolygonPolygon(currentPolygon, siblingPolygon, response);

		if (collided) {
			// If collided, resolve the overlap immediately
			const overlapVector = response.overlapV.clone().scale(-1); // Push currentShape away
			const snappedX = position.x + overlapVector.x;
			const snappedY = position.y + overlapVector.y;

			closestSnapPoint = { x: snappedX, y: snappedY };
		} else {
			// No collision, check snapping based on vertices (edges) of the shapes
			const currentVertices = currentPolygon.points;
			const siblingVertices = siblingPolygon.points;

			// Check each vertex of the current shape against all vertices of the sibling shape
			currentVertices.forEach((currentVertex) => {
				siblingVertices.forEach((siblingVertex) => {
					const distance = Math.hypot(
						siblingVertex.x - currentVertex.x,
						siblingVertex.y - currentVertex.y
					);

					// If the distance is within the snapping threshold, update closestSnapPoint
					if (distance < snapThreshold && distance < closestDistance) {
						const snapOffsetX = siblingVertex.x - currentVertex.x;
						const snapOffsetY = siblingVertex.y - currentVertex.y;

						closestSnapPoint = {
							x: position.x + snapOffsetX,
							y: position.y + snapOffsetY,
						};
						closestDistance = distance;
					}
				});
			});
		}
	});

	// Return the snapped position (or null if no snapping is needed)
	return closestSnapPoint ? { x: closestSnapPoint.x, y: closestSnapPoint.y } : null;
}

// Utility to convert a Konva shape (rotated Line) into an SAT.js polygon
function convertToSATPolygon(shape: Konva.Line, position: { x: number; y: number }): Polygon {
	const points = shape.points();
	const vectors = [];
	const rotation = shape.rotation(); // Rotation in degrees
	const rotationRad = degToRad(rotation); // Convert to radians
	const shapeCenter = shape.getClientRect(); // Get bounding box to calculate the center

	// Apply rotation to each point and convert to SAT.js vectors
	for (let i = 0; i < points.length; i += 2) {
		const x = points[i];
		const y = points[i + 1];

		// Calculate the rotated position using the rotation matrix
		const rotatedX =
			Math.cos(rotationRad) * (x - shapeCenter.width / 2) -
			Math.sin(rotationRad) * (y - shapeCenter.height / 2) +
			shapeCenter.width / 2 +
			position.x;
		const rotatedY =
			Math.sin(rotationRad) * (x - shapeCenter.width / 2) +
			Math.cos(rotationRad) * (y - shapeCenter.height / 2) +
			shapeCenter.height / 2 +
			position.y;

		vectors.push(new Vector(rotatedX, rotatedY));
	}

	return new Polygon(new Vector(0, 0), vectors); // The origin can be set as (0, 0)
}

type bbox={
	width: number;
	height: number;
	x: number;
	y: number;
}
function isBoundingBoxesIntersect(boxA:bbox, boxB:bbox) {
	return !(
		boxA.x > boxB.x + boxB.width ||
    boxA.x + boxA.width < boxB.x ||
    boxA.y > boxB.y + boxB.height ||
    boxA.y + boxA.height < boxB.y
	);
}

function normalizePointsToStage(shape: Konva.Line) {
	const stage = shape.getStage();
	if(!stage) return [];
	const points = shape.points();
	const stagePosition = stage.position();

	const transfom = shape.getAbsoluteTransform();
	const normalizedPoints= [];
	for (let i = 0; i < points.length; i += 2) {
		const point = { x: points[i], y: points[i + 1] };
		const transformedPoint= transfom.point(point);
		const normalizedX = transformedPoint.x - stagePosition.x;
		const normalizedY = transformedPoint.y - stagePosition.y;
		normalizedPoints.push([ normalizedX, normalizedY ]);
	}

	return normalizedPoints.flat();
}

type PanelConfig = {
	orientation: RasterRoofSegment['orientation'];
	width: number;
	height: number;
}

export function getPanelPointsFromConfig(panel: PanelConfig, scale: Vector2d){
	const scaledWidth = (panel.width)*scale.x;
	const scaledHeight = (panel.height)*scale.y;

	const isPortrait = panel.orientation=== 'POTRAITT';

	const halfWidth = (isPortrait ? scaledHeight: scaledWidth)/2;
	const halfHeight = (isPortrait ? scaledWidth	: scaledHeight)/2;

	// Define the points relative to the center (x, y)
	const points = [
		-halfWidth, - halfHeight,
		halfWidth, - halfHeight,
		halfWidth, halfHeight,
		- halfWidth, halfHeight,
		- halfWidth, - halfHeight,
	];

	return points;

}

export function getRotatedLinePoints(
	mousePos: { x: number; y: number },
	width: number,
	height: number,
	rotationDegrees: number,
) {
	const rotationRadians = rotationDegrees * (Math.PI/180);
	
	// Calculate half-width and half-height
	const halfWidth = (width) / 2;
	const halfHeight = (height) / 2;

	// Original corner points based on the shape's dimensions
	const originalPoints = [
		-halfWidth, -halfHeight, // Top-left
		halfWidth, -halfHeight, // Top-right
		halfWidth, halfHeight, // Bottom-right
		-halfWidth, halfHeight // Bottom-left
	];

	const rotatedPoints = [];
	
	for (let i = 0; i < originalPoints.length; i += 2) {
		const x = originalPoints[i];
		const y = originalPoints[i + 1];

		// Rotate the point
		const rotatedX =
					mousePos.x + (x * Math.cos(rotationRadians) - y * Math.sin(rotationRadians));
		const rotatedY =
					mousePos.y + (x * Math.sin(rotationRadians) + y * Math.cos(rotationRadians));

		rotatedPoints.push(rotatedX, rotatedY);
	}

	return rotatedPoints;
}

export function checkIntersection(shapes: Konva.Line[], targetShape: Konva.Line) {
	let bestShape: Konva.Line | null = null;
	let maxIntersectionArea = 0; // Track the maximum intersection area

	// Convert target shape's points to a polygon
	const targetPolygon = lineToPolygon(normalizePointsToStage(targetShape));
	const targetBox = targetShape.getClientRect(); // Get the bounding box of the target shape

	for (const shape of shapes) {
		shape.fill(FACET_HIGHLIGHT_OFF_COLOR);
		// Convert each shape's points to a polygon
		const shapeBox = shape.getClientRect();

		// Check for bounding box intersection first to optimize
		if (!isBoundingBoxesIntersect(targetBox, shapeBox)) {
			continue;
		}

		const shapePolygon = lineToPolygon(normalizePointsToStage(shape));

		// Calculate intersection using polygon-clipping
		const intersectionPolygon = intersection(targetPolygon as Geom, shapePolygon as Geom);

		if (intersectionPolygon && intersectionPolygon.length > 0) {
			const intersectionArea = calculatePolygonArea(intersectionPolygon[0][0]); // Area of the intersection
					
			// Check if the current intersection area is greater than the max found so far
			if (intersectionArea > maxIntersectionArea) {
				maxIntersectionArea = intersectionArea;
				bestShape = shape;
			}
		}
	}

	if (bestShape) {
		bestShape.fill(FACET_HIGHLIGHT_COLOR); // Highlight the most intersected shape
	}

	return bestShape;
}

const lineToPolygon = (linePoints: number[]) => {
	const polygon = [];
	for (let i = 0; i < linePoints.length; i += 2) {
		polygon.push([linePoints[i], linePoints[i + 1]]); // Pairs [x, y]
	}
	// Ensure the polygon is closed by repeating the first point at the end
	if (polygon.length > 0 && (polygon[0][0] !== polygon[polygon.length - 1][0] || polygon[0][1] !== polygon[polygon.length - 1][1])) {
		polygon.push(polygon[0]); // Close the polygon
	}
	return [polygon]; // Returning a MultiPolygon (array of polygons)
};

const calculatePolygonArea = (polygon: number[][]): number => {
	let area = 0;
	const n = polygon.length;

	for (let i = 0; i < n - 1; i++) {
		area += polygon[i][0] * polygon[i + 1][1] - polygon[i + 1][0] * polygon[i][1];
	}
	area += polygon[n - 1][0] * polygon[0][1] - polygon[0][0] * polygon[n - 1][1];
	return Math.abs(area) / 2; // Return absolute value divided by 2
};

export function generatePolygon(linePoints:number[]) {
	const vectors = linePoints.reduce<Vector[]>((acc, value, index) => {
		if (index % 2 === 0) {
			acc.push(new Vector(value, linePoints[index + 1]));
		}
		return acc;
	}, []);
	return new Polygon(new Vector(0, 0), vectors);
}

export function doesPolygonOverLap(polygon1: Polygon, polygon2: Polygon) {
	return testPolygonPolygon(polygon1, polygon2);
}

export const isOverLap = (movablePanel: Konva.Line, relativePanels: Konva.Line[], threshold = 1) => {
	const shrinkPolygon = (polygon: Polygon, threshold: number) => {
		const points = polygon.points.map((point) => point.clone()); // Clone the original points
		const centroidX = points.reduce((sum, point) => sum + point.x, 0) / points.length; // Avg X
		const centroidY = points.reduce((sum, point) => sum + point.y, 0) / points.length; // Avg Y
		const centroid = new Vector(centroidX, centroidY);
	
		// Scale points relative to the calculated centroid
		const shrunkPoints = points.map((point) => {
			const direction = point.clone().sub(centroid); // Direction from centroid
			return centroid.clone().add(direction.scale(1 - threshold)); // Scale inward
		});
	
		// Return a new polygon with the shrunk points
		return new Polygon(polygon.pos.clone(), shrunkPoints);
	};

	const extractPoints = (polygon: Polygon) => {
		const points: number[] = [];
		polygon.points.forEach((point) => {
			points.push(point.x, point.y);
		});
		return points;
	};

	const movablePolygon = convertToSATPolygon(movablePanel, movablePanel.position());
	const shrunkMovablePolygon = shrinkPolygon(movablePolygon, threshold);

	const predefinedPolygons = relativePanels.map((panel: Konva.Line) =>
		convertToSATPolygon(panel, panel.position())
	);

	// Debug: Get points of the shrunk polygon to visualize
	const shrunkPolygonPoints = extractPoints(shrunkMovablePolygon);
	
	const hasOverlap = predefinedPolygons.some((polygon: Polygon) =>
		testPolygonPolygon(shrunkMovablePolygon, polygon)
	);

	return { hasOverlap, shrunkPolygonPoints };
};
function removeDuplicatePoint(arr: number[][]): number[][] {
	if(arr[0][0] === arr[arr.length-1][0] && arr[0][1] === arr[arr.length-1][1]){
		arr.pop();
	}
	return arr;
}

function isPointInPolygon(smallPolygon: number[][], polygon: number[][]): boolean {
	if (smallPolygon.length !== polygon.length) return false;

	const a1 = removeDuplicatePoint(smallPolygon);
	const b1 = removeDuplicatePoint(polygon);

	const sortArray = (arr: number[][]): number[][] => arr.sort((a, b) => a[0] - b[0]);
	const strArr1 = JSON.stringify(sortArray(a1));
	const strArr2 = JSON.stringify(sortArray(b1));

	return strArr1 === strArr2;
}

export function isCompletelyInsideFacet(smallPolygonPoints: number[], largePolygonPoints: number[]): boolean {
	const smallPolygon = lineToPolygon(smallPolygonPoints);
	const targetPolygon = lineToPolygon(largePolygonPoints);

	const intersectionResult = intersection(smallPolygon as Geom, targetPolygon as Geom);

	if (!intersectionResult?.[0]?.[0]?.length) return false;

	const smallPolygonArea = calculatePolygonArea(smallPolygon[0]);
	const intersectionArea = calculatePolygonArea(intersectionResult[0][0]);
	const threshold = 0.98;
	return intersectionArea >= smallPolygonArea * threshold;
}
