/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PanelEnergyPayloadType, EnergyState, SliderEnergyPayloadType } from './types';
import { getAnnualEnergyFromBillAmount, getDeratedValue, getPercentage } from '../../../util';
import { getSolarEnergyDetails } from 'components/tool/utils';
import {
	fetchRoofData,
	showAllPanels,
	suggestNewPanelAlignment,
	changePanel,
	recalcuatedEnergyVals,
} from '../RoofDataSlice/roofDataSlice';
import { RootState } from 'store';
import { getUpdatedEnergyDataToSendToParent, updateEnergyByPanels, updateEnergyBySlider } from './util';
import { IntermediateOffsetType } from '../OffsetModelSlice/types';
import { IRRADIANCE_UPRATE_RATE } from '../../../constants';

const initialState: { data: null | EnergyState } = {
	data: null,
};

function logEnergyChange(energyState: EnergyState) {
	console.log(` 
	design attributes with derate factor  ${ energyState.deratedBy } totalPanelsSelected ${ energyState.totalNoOfPanelsSelectedByUser }\n
	deratedYearlyEnergy ${ energyState.deratedEnergy } , normalYearlyEnergy ${ energyState.energy } \n
	average upratedIrradiance ${ energyState.upratedIrradiance/energyState.totalNoOfPanelsSelectedByUser } , avergae normalIrradiance ${ energyState.irradiance/energyState.totalNoOfPanelsSelectedByUser } \n
	uprate irradiance rate ${IRRADIANCE_UPRATE_RATE}
`);
}

export const negateEnergyProductionOfPanelsWhichAreStillInEditMode =
	createAsyncThunk('energy/negateEnergy', async (roofIndexes: string[], thunkApi) => {
		const { roofSegsFromWhichUserHasSelectedPanels, rasterSolarPanels, panel, selectedPanels } = (thunkApi.getState() as RootState).roofData.data;
		const energyState = (thunkApi.getState() as RootState).energyData.data as EnergyState;
		const energyDerateRate = (thunkApi.getState() as RootState).QueryParams.data.energyDerateRate;
		const roofIndexesToNegate = roofSegsFromWhichUserHasSelectedPanels.filter(ind => roofIndexes.includes(ind));
		if (!roofIndexesToNegate.length) return energyState;

		const negateEnergy = getSolarEnergyDetails(
			rasterSolarPanels.filter(p => !roofIndexesToNegate.includes(p.segmentId)),
			panel.wattage,
			selectedPanels,
			energyDerateRate
		);

		return {
			...energyState,
			...negateEnergy,
		};

	});

export const energyProviderSlice = createSlice({
	name: 'energySlice',
	initialState,
	reducers: {
		updateEnergyStateFromPanelClicks(state, action: PayloadAction<PanelEnergyPayloadType>) {
			if (state.data === null) {
				return;
			}
			const { energyBoost, panelId, irradianceBoost } = action.payload;
			const { selectedPanels, energy, irradiance } = state.data;
			
			const updatedEnergy = {
				...state.data,
				...updateEnergyByPanels(
					selectedPanels, 
					{ energy, irradiance },
					{ panelId, energyBoost, irradianceBoost },
					state.data.deratedBy,
				),
			};
			logEnergyChange(updatedEnergy);
			window.parent.postMessage(
				{
					id: 'SOLARQUOTEEMBED_ENERGYUPDATE',
					detail: getUpdatedEnergyDataToSendToParent(updatedEnergy)
				},
				'*'
			);
			state.data = updatedEnergy;
		},
		updateEnergyStateFromSlider(state, action: PayloadAction<SliderEnergyPayloadType>) {
			const { energy, selectedPanels, irradiance } = action.payload;
			const updatedEnergy = {
				...state.data as EnergyState,
				energy,
				deratedEnergy: getDeratedValue(energy, state.data!.deratedBy),
				totalNoOfPanelsSelectedByUser: selectedPanels.length,
				selectedPanels,
				energyDerateRate: state.data?.deratedBy,
				irradiance,
				upratedIrradiance: irradiance + getPercentage(irradiance, IRRADIANCE_UPRATE_RATE),
			};
			window.parent.postMessage(
				{
					id: 'SOLARQUOTEEMBED_ENERGYUPDATE',
					detail: getUpdatedEnergyDataToSendToParent(updatedEnergy)
				},
				'*'
			);
			logEnergyChange(updatedEnergy);
			state.data = updatedEnergy;
		},
		updateEnergyOnUserConfirmation(state, action: PayloadAction<{ rasterSolarPanels: RasterSolarPanel[], val: IntermediateOffsetType; }>) {
			const {
				rasterSolarPanels = [],
				val: {
					source,
					data: { panelCount, panelInfo },
				},
			} = action.payload;
			let updatedEnergy = { ...state.data } as EnergyState;

			if (source === 'slider' && panelCount) {
				updatedEnergy = {
					...updatedEnergy,
					...updateEnergyBySlider(panelCount, rasterSolarPanels, state.data!.deratedBy),
				};
			} else if (source === 'panel' && panelInfo) {
				updatedEnergy = {
					...updatedEnergy,
					...updateEnergyByPanels(
						updatedEnergy.selectedPanels,
						{ energy: updatedEnergy.energy, irradiance: updatedEnergy.irradiance },
						{
							energyBoost: panelInfo.panel.yearlyEnergyDcKwh,
							panelId: panelInfo.panelId,
							irradianceBoost: panelInfo.panel.irradiance,
						},
						state.data!.deratedBy
					),
				};
			}
			logEnergyChange(updatedEnergy);
			window.parent.postMessage({ id: 'SOLARQUOTEEMBED_ENERGYUPDATE', detail: getUpdatedEnergyDataToSendToParent(updatedEnergy) }, '*');
			state.data = updatedEnergy;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRoofData.fulfilled, (state, action) => {
				const { billAmount, electricUnitPrice, annualEnergy } = action.payload.metaData;
				state.data = {
					...getSolarEnergyDetails(
						action.payload.roofData.rasterSolarPanels,
						action.payload.roofData.panel.wattage,
						action.payload.roofData.selectedPanels,
						action.payload.energyDerateRate
					),
					billEnergy: annualEnergy,
					currentBillAmount: billAmount,
					annualInputEnergy: annualEnergy,
					electricUnitPrice,
				};
				logEnergyChange(state.data);
			})
			.addCase(suggestNewPanelAlignment.fulfilled, (state, action) => {
				state.data =
				{
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					...state.data!,
					...getSolarEnergyDetails(
						action.payload.roofState.rasterSolarPanels,
						action.payload.roofState.panel.wattage,
						action.payload.roofState.selectedPanels,
						action.payload.energyDerateRate
					),
					...action.payload.energyState
				};
				logEnergyChange(state.data);
			}).
			addCase(showAllPanels.fulfilled, (state, action) => {
				state.data =
				{
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					...state.data!,
					...getSolarEnergyDetails(
						action.payload.roofState.rasterSolarPanels,
						action.payload.roofState.panel.wattage,
						action.payload.roofState.selectedPanels,
						action.payload.energyDerateRate
					),
					...action.payload.energyState
				};
				logEnergyChange(state.data);
			}).addCase(changePanel.fulfilled, (state, action) => {
				state.data = {
					...getSolarEnergyDetails(
						action.payload.roofData.rasterSolarPanels,
						action.payload.roofData.panel.wattage,
						action.payload.roofData.selectedPanels,
						action.payload.energyDerateRate
					),
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					billEnergy: getAnnualEnergyFromBillAmount(action.payload.metaData.billAmount, state.data!.electricUnitPrice),
					currentBillAmount: action.payload.metaData.billAmount,
					annualInputEnergy: action.payload.metaData.annualEnergy,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					electricUnitPrice: state.data!.electricUnitPrice,
				};
				logEnergyChange(state.data);
			}).addCase(recalcuatedEnergyVals.fulfilled, (state, action) => {
				state.data = {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					...state.data!,
					...getSolarEnergyDetails(
						action.payload.roofState.rasterSolarPanels,
						action.payload.roofState.panel.wattage,
						action.payload.roofState.selectedPanels,
						action.payload.energyDerateRate
					),
					...action.payload.energyState
				};
				logEnergyChange(state.data);
			}).addCase(negateEnergyProductionOfPanelsWhichAreStillInEditMode.fulfilled, (state, action) => {
				state.data = action.payload;
				logEnergyChange(state.data);
			});
	}
});

export const { updateEnergyStateFromPanelClicks, updateEnergyStateFromSlider, updateEnergyOnUserConfirmation } = energyProviderSlice.actions;

export default energyProviderSlice.reducer;