import { AxiosError } from 'axios';
import { movablePanels } from 'components/tool/Editor/ButtonDiv/api';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { errorResponseHandler } from 'store/api/AxiosError';

export default function useMovePanelsQuery() {
	const movedPanels = useSelector((state: RootState) => state.panelSlice.data.currentMovablePanels);
	const { uuid, panel } = useSelector((state: RootState) => state.roofData.data);

	return useMutation(
		'move-panels',
		async () => await movablePanels(movedPanels, panel.key, uuid),
		{
			onError(error: AxiosError) {
				const message = errorResponseHandler(error).message;
				toast.error(message);
			},
		}
	);
}