import styled from 'styled-components';

const ToggleBtnWrap = styled.div`

		#button-13{
				height: 22px;
    		width: 40px;
			.knobs{
				border-radius: 22px;
				height: 22px;
    		width: 40px;
				background-color: rgb(189, 189, 189);
				border: 1.5px solid rgb(189, 189, 189);
				transition: 0.25s;
				&:after,&:before{
					content: '' !important;
				}
				span{
					width: 20px;
					height:20px;
					left:0px;
					top:0;
					box-shadow: none;
					transition: 0.25s;
				}
			}
		.checkbox:checked + .knobs:before{
			display:none;
		}

			.checkbox:checked + .knobs span {
					left: 17px;
			}
		}	
		}

`;

export {
	ToggleBtnWrap
};