import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { resizePoints } from '../utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

export default function RawConcaveHullLines() {
	const [showLines, setShowLines] = useState(false);
	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const scale = useKonvaImageScale();

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if ((event.key === 'r' || event.key === 'R')) {
				event.preventDefault();
				setShowLines(true);
			}
		};

		const handleKeyUp = (event: KeyboardEvent) => {
			setShowLines(false);
		};

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, []);

	const transformedRoofSegments = useMemo(() => (
		allRoofSegs.map(segment => {
			const transformedPoints = resizePoints(segment.hullCoords.flat(), scale);
			return {
				points: transformedPoints,
				id: segment.id,
			};
		})), [allRoofSegs, scale]);

	if (!showLines) {
		return null;
	}

	return (
		<>
			{
				transformedRoofSegments.map(eachSeg => (
					(<Line
						key={eachSeg.id}
						stroke={'#66FF00'}
						strokeWidth={2}
						points={eachSeg.points}
					/>))
				)
			}
		</>
	);
}