import { useIsFetching, useIsMutating, useQueryClient } from 'react-query';

const useQueriesRunningInBackGround = (keys: string[] = [], type: 'mutating' | 'fetching'= 'fetching') => {
	
	const queryClient = useQueryClient();
	const isFetching = !!useIsFetching({ fetching: true });
	const isMutating = !!useIsMutating({ fetching: true });
	
	if (type === 'fetching' && keys.length){
		return (keys.some(queryKey => !!queryClient.isFetching({ queryKey })));
	}
	if (type === 'mutating' && keys.length)
		return (keys.some(mutationKey => !!queryClient.isMutating({ mutationKey, exact: true, fetching: true })));

	return isFetching || isMutating;

};

export default useQueriesRunningInBackGround;