import styled, { Interpolation } from 'styled-components';

type props = {
	styles?: Interpolation<React.CSSProperties>
}
const InputStyle = styled.input<props>`
	background: #fff;
  appearance: none;
  border: 1px solid #e9e9e9;
  padding: 0.75em;
  border-radius: 4px;
	width: 100%;
  display: block; 
	font-size: 0.95rem;
	color: #2E384D;
	${({ styles }) => styles}
	&:disabled {
		opacity: 0.9 !important;
  }
}
	&.input-annual-usage{
		padding: 0.484rem 0.65rem;
		line-height: 1.35;
	}
	&.input-annual-usage,&.input-bill{
		padding: 0.484rem 0.65rem;
		line-height: 1.35;
	}	
`;

export {
	InputStyle
};