import { ModalPopup, Modalbutton, ModalbuttonNo, ModalbuttonWrap, ModalcontentInfo } from 'components/OffsetModal/styles';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { AppDispatch, RootState } from 'store';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { IMAGERY_PROVIDER, API_ERROR_CODES, DESIGN_MODES } from '../../constants';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { useMutation } from 'react-query';
import { switchDesignImagery } from './api';
import { toast } from 'react-toastify';
import { errorResponseHandler } from 'store/api/AxiosError';
import { AxiosError } from 'axios';
import { SVGMap, StyledSelect } from './styles';
import { components, SingleValueProps } from 'react-select';

export type Option = { value: string, label: string };

const message = 'This will reset all your progress on the map. Are you sure you want to continue?';
const ImageryProviderOptions = [
	{
		label: '— Imagery Provider —',
		options: [
			{ value: IMAGERY_PROVIDER.SUNROOF, label: 'Google Map HD' },
			{ value: IMAGERY_PROVIDER.NEARMAP, label: 'Nearmap' },
		],
	},
];

const CustomSingleValue = (props: SingleValueProps<Option>) => {
	return (
		<components.SingleValue {...props}>
			<SVGMap className='imageIcon'
				width="19" height="14" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M18.8372 12.186V1.60465C18.8372 1.27071 18.5665 1 18.2326 1H1.60465C1.27071 1 1 1.27071 1 
				1.60465V13.3953C1 13.7293 1.27071 14 1.60465 14H6.44186M18.8372 12.186V13.3953C18.8372 13.7293 18.5665 
				14 18.2326 14H6.44186M18.8372 12.186L15.2093 8.55814C14.3023 7.65116 13.2442 7.19767 11.8837 8.55814L6.44186 14"
				stroke="#64686A" strokeWidth="1.6" />
				<circle cx="5.83739" cy="5.83788" r="1.81395" stroke="#64686A" strokeWidth="1.6" />
			</SVGMap> <span>{props.data.label}</span>
		</components.SingleValue>
	);
};

export default function SwitchProvider() {
	const { uuid, panel, roofDataChanging, imagerySource, mode } = useSelector((state: RootState) => ({
		...state.roofData.data,
		roofDataChanging: state.roofData.configuringNewPanel || state.roofData.loading || state.roofData.switchingImageryProvider
	}));
	const [selectorImageProvidor, setSelectorImageProvidor] = useState(imagerySource);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch<AppDispatch>();
	const allQueriesRunningInBackGround = useQueriesRunningInBackGround();
	const energyQueriesInProgrss = useQueriesRunningInBackGround(['save', `monthlyDetails-${uuid}`, `hourlyEnergy-${uuid}`]);
	const disableSelect = roofDataChanging || energyQueriesInProgrss || allQueriesRunningInBackGround || mode !== DESIGN_MODES.DEFAULT;

	const switchDesignImageryQuery = useMutation({
		mutationKey: `switch-imagery-${imagerySource}`,
		mutationFn: async () => {
			console.log(`switch-imagery-${imagerySource}`);
			await switchDesignImagery({ uuid, panelKey: panel.key, source: selectorImageProvidor });
		},
		onSuccess() {
			dispatch(changePanel({ uuid, panelKey: panel.key, switchingSource: true }));
		},
		onError(error) {
			// eslint-disable-next-line prefer-const
			let { message, error: errCode } = errorResponseHandler(error as AxiosError);
			let errorId = 'switch-imagery' + message;
			if (errCode === API_ERROR_CODES.INVALID_IMAGERY_CHECKOUT) {
				message = 'The imagery you are trying to checkout isn\'t available for this address';
				errorId = API_ERROR_CODES.INVALID_IMAGERY_CHECKOUT;
			}
			if (toast.isActive(errorId)) return;
			toast.error(message, {
				toastId: errorId
			});
		},
		retry(failureCount, error) {
			const { error: parsedErrorMsg } = errorResponseHandler(error as AxiosError);
			return parsedErrorMsg !== API_ERROR_CODES.INVALID_IMAGERY_CHECKOUT && failureCount < 2;
		},
	});

	const selectedIMageryProviderOption = useMemo(() =>
		ImageryProviderOptions[0].options.find(o => o.value === imagerySource), [imagerySource]);

	const handleChange = (option: Option) => {
		const currentProvider = option.value as ImagerySource;
		setOpen(true);
		setSelectorImageProvidor(currentProvider);
	};

	const handleConfirmation = async () => {
		await switchDesignImageryQuery.mutateAsync();
		setOpen(false);
	};

	return (
		<>
			<StyledSelect
				isDisabled={disableSelect}
				isMulti={false}
				options={ImageryProviderOptions}
				className="imagary-provider-select"
				classNamePrefix="cus-select"
				value={selectedIMageryProviderOption}
				isSearchable={false}
				onChange={(option) => handleChange(option as Option)}
				components={{ SingleValue: CustomSingleValue }}
			/>

			<Popup
				contentStyle={{ width: '560px', borderRadius: '4px' }}
				open={open}
				closeOnDocumentClick={false}
				onClose={() => {
					if (open)
						setOpen(false);
				}}
			>
				<ModalPopup>
					<ModalcontentInfo>
						{message}
					</ModalcontentInfo>

					<ModalbuttonWrap>
						<ModalbuttonNo onClick={() => { setOpen(false); }}>
							No
						</ModalbuttonNo>
						<Modalbutton onClick={handleConfirmation}>
							Yes
						</Modalbutton>
					</ModalbuttonWrap>
				</ModalPopup>
			</Popup>
		</>
	);
}