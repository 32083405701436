import PanelSelector from '../PanelSelector';
import { PanelElement } from '../style';

interface PanelSelectorProps {
	getPanelDetailsForOrgData: {
		panelDetails: PanelDetails[];
		panelFinancingInfo: panelFinancingInfo[];
	} | undefined;
	selectedPanelKey: string;
}

export default function ChnagePanelSelector({ getPanelDetailsForOrgData, selectedPanelKey }: PanelSelectorProps) {
	return (<>
		{!!getPanelDetailsForOrgData?.panelDetails?.length &&
			<PanelElement className="change-panel-title">
				<h4>Change Panel</h4>
				<PanelSelector
					panelDetails={getPanelDetailsForOrgData.panelDetails as PanelDetails[]}
					panelFinancingInfo={getPanelDetailsForOrgData.panelFinancingInfo as panelFinancingInfo[]}
					panelKey={selectedPanelKey}
				/>
			</PanelElement>
		}
	</>
	);
}