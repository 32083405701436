import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleRecalculateLayoutModalSliceState } from 'store/slices/RecalculateLayoutSlice';

import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { toggleManualToolDoneButtonState, toggleManualToolModalState, toggleManualToolResetButtonState } from 'store/slices/ManualToolModal';

import useCreateDeleteFacet from 'hooks/api/facet/useCreateDeleteFacet';
import useDeleteObstructionQuery from 'hooks/api/deleteObstruction';
import useTranslatePanelPositionsMutation from 'hooks/api/facet/useTranslateFacet';
import useRemovePanelsQuery from 'hooks/api/panel/addPanel';
import useMovePanelsQuery from 'hooks/api/panel/movePanel';
import useAddPanelsQuery from 'hooks/api/panel/addPanel';
import { setPanelMove, setAddPanel, setDeleteIndividualPanelsMode } from 'store/slices/PanelSlice';
import { setDeletedRoofIndexes, setRoofIndexes, 
	resetDeletedObstructionIDs, setUserModificationState, updateTransition } from 'store/slices/ToolSlice';

export default function RecalculateLayoutModal() {
	const { open, warningMessage } = useSelector(
		(state: RootState) => state.recalculateLayoutModal.data
	);
	const { newlyCreatedFacets, deletedRoofIndexes, roofIndexes, deletedObstructionIDs } = 
	useSelector((state: RootState) => state.toolNewPostions.data);
	const { newlyAddedPanels, deletedPanels, currentMovablePanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { uuid, panel } = useSelector((state: RootState) => state.roofData.data);

	const dispatch = useDispatch<AppDispatch>();

	const hasTransformedFacet = !!roofIndexes.length;
	const hasCreatedFacet = !!Object.keys(newlyCreatedFacets).length;
	const hasDeletedFacet = !!deletedRoofIndexes.length;
	const hasDeletedObstruction = !!deletedObstructionIDs.length;

	const hasPanelsAdded = newlyAddedPanels.length > 0;
	const hasPanelsDeleted = deletedPanels.length > 0;
	const hasPanelsMoved = currentMovablePanels.length > 0;

	const createNewFacetsMutation = useCreateDeleteFacet();
	const translatePanelPositionsMutation = useTranslatePanelPositionsMutation();

	const addNewPanelsQuery = useAddPanelsQuery();
	const removePanelsQuery = useRemovePanelsQuery();
	const movePanelsQuery = useMovePanelsQuery();

	const deleteObstructionsQuery = useDeleteObstructionQuery();

	function resetAllMOdifiedState(){
		dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		dispatch(setAddPanel({ shouldEnable: false }));
		dispatch(setDeleteIndividualPanelsMode({ enabled: false }));
	
		dispatch(setDeletedRoofIndexes({ deletedRoofIndexes: [] }));
		dispatch(setRoofIndexes({ roofIndexes: [] }));
			
		dispatch(resetDeletedObstructionIDs());
		dispatch(setUserModificationState({ hasMadeChanges: true }));
		dispatch(updateTransition({ currentTransitionState: false }));

		dispatch(toggleManualToolModalState({ value: false }));
		dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: false }));
		dispatch(toggleManualToolResetButtonState({ resetEnabled: false }));
	}

	async function handleClick(){
		const mutations = [];
		if(hasCreatedFacet || hasDeletedFacet) mutations.push(createNewFacetsMutation.mutateAsync());

		if (hasPanelsAdded) mutations.push(addNewPanelsQuery.mutateAsync());
		if (hasPanelsDeleted) mutations.push(removePanelsQuery.mutateAsync());
		if (hasPanelsMoved) mutations.push(movePanelsQuery.mutateAsync());

		if (hasDeletedObstruction) mutations.push(deleteObstructionsQuery.mutateAsync());
		if(hasTransformedFacet) mutations.push(translatePanelPositionsMutation.mutateAsync());

		await Promise.allSettled(mutations);

		await dispatch(changePanel({ uuid, panelKey: panel.key }));
		resetAllMOdifiedState();
	}

	function hanldeConfirmation() {
		closeModal();
		handleClick();
	}

	const closeModal = () => {
		dispatch(toggleManualToolModalState({ value: true, showAll: true }));
		dispatch(toggleRecalculateLayoutModalSliceState({
			open: false,
			warningMessage: '',
		}));
	};

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open) closeModal();
			}}
		>
			<ModalPopup>
				<ModalcontentInfo>
					{warningMessage}
				</ModalcontentInfo>
				<ModalbuttonWrap>
					<Modalbutton onClick={() => hanldeConfirmation()}>
											Yes
					</Modalbutton>
					<ModalbuttonNo onClick={() => closeModal()}>
											No
					</ModalbuttonNo>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}
