import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { convertObjValsToNum, debounce, removeFalsyValueFromObj } from '../../../util';
import Input from '../../Basic/Input';
import { updateDesignBillAmount } from '../api';
import { suggestNewPanelAlignment, recalcuatedEnergyVals } from 'store/slices/RoofDataSlice/roofDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { PARENT_EVENTS, SUBHUB_APP_NAME } from '../../../constants';
import { setThreeDState } from 'store/slices/ToolSlice';

type Props = {
	setError: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function BillInput(props: Props) {

	const [billAmountError, setBillAmountError] = useState('');
	const { uuid } = useSelector((state: RootState) => state.roofData.data);
	const { currentBillAmount } = useSelector((state: RootState) => state.energyData.data as unknown as EnergyState);
	const { parentAppName } = useSelector((state: RootState) => state.QueryParams.data);
	const billAmountRef = useRef<number>(currentBillAmount);
	const dispatch = useDispatch<AppDispatch>();
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const disable3D = () => {
		dispatch(setThreeDState({ enabled: false }));
	};

	const iframeIsEmbeddedInSubhub = parentAppName === SUBHUB_APP_NAME;
	const { setError } = props;

	const updateBillAmountQuery = useQuery(
		`updateBillAmount-${uuid}`,
		async () => {
			// const billAmountRef.current = inputRef.current?.value || 1;
			if (billAmountError) return;
			const requestData = await updateDesignBillAmount(
				uuid,
				billAmountRef.current
			);
			return requestData;
		},
		{
			cacheTime: 0,
			refetchOnWindowFocus: false,
			enabled: false,
			retry: false,
			onError: () => {
				toast.error('Invalid Bill Amount');
			},
		}
	);

	const billAmount = useMemo(() => {
		if (currentBillAmount < 50 || currentBillAmount > 10000) {
			setBillAmountError('bill amount must be in between 50 and 10000.');
			setError(true);
		} else {
			setBillAmountError('');
			setError(false);
		}
		return currentBillAmount;
	}, [currentBillAmount]);

	const debouncedChangeBillAmount = useMemo(
		() =>
			debounce(() => {
				const dispatchFunc = iframeIsEmbeddedInSubhub ? recalcuatedEnergyVals : suggestNewPanelAlignment;
				dispatch(dispatchFunc({ billAmount: +billAmountRef.current }));
				updateBillAmountQuery.refetch();
			}, 600),
		[]
	);

	useEffect(() => {
		function changeBillInputs(event: MessageEvent) {
			if (event.data?.eventType !== PARENT_EVENTS.ENERGY_INPUTS_CHANGED || !iframeIsEmbeddedInSubhub)
				return;
			console.log(PARENT_EVENTS.ENERGY_INPUTS_CHANGED, event);
			const electricInputChanges = convertObjValsToNum(removeFalsyValueFromObj(event.data?.values));
			const { billAmount, annualUsage: energy } = electricInputChanges;
			if (!billAmount && !energy) return;
			dispatch(recalcuatedEnergyVals({ ...electricInputChanges, energy }));
		}
		window.addEventListener('message', changeBillInputs);
		return () => {
			window.removeEventListener('message', changeBillInputs);
		};
	},[dispatch, iframeIsEmbeddedInSubhub]);

	return (
		<Input
			className='input-bill'
			key={billAmount}
			error={billAmountError}
			defaultValue={billAmount}
			disabled={iframeIsEmbeddedInSubhub}
			onChange={(event) => {
				const value = +event.target.value;
				if (threeDModelEnabled)
					disable3D();
				if (isNaN(value)) {
					setBillAmountError('bill amount must be number.');
					setError(true);
					return;
				}
				if (value < 50 || value > 10000) {
					setBillAmountError('bill amount must be in between 50 and 10000.');
					setError(true);
					return;
				}
				billAmountRef.current = value;
				setBillAmountError('');
				setError(false);
				debouncedChangeBillAmount();
			}}
		/>
	);
}
