import Button from 'components/Basic/Button';
import { checkPanelDrawerHasUnfinalizedFacets, deleteAllFacetsFromPanelDrawer } from 'components/tool/utils';
import { useRefs } from 'contexts/RefContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { setDrawModeState, toggleGroundMount } from 'store/slices/ToolSlice';
import { useState } from 'react';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function DrawBtn() {

	const { drawModeEnabled, roofIndexes, currentActiveFacetEditMode } = useSelector((state: RootState) => state.toolNewPostions.data);
	const defaultToGroundMountFacet = useSelector((state: RootState) => state.roofData.data.missingRoofPolygon);

	const { activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const queriesRunningInBackground = useQueriesRunningInBackGround();
	const shouldDisable = queriesRunningInBackground || activePanelMode || 
	roofIndexes.length > 0 || (currentActiveFacetEditMode && currentActiveFacetEditMode != 'DRAW_MODE');
	const { konvaRef } = useRefs();
	const dispatch = useDispatch<AppDispatch>();

	function handleDrawFacetClick() {
		if (drawModeEnabled && konvaRef?.current && checkPanelDrawerHasUnfinalizedFacets(konvaRef.current)) {
			deleteAllFacetsFromPanelDrawer(konvaRef.current);
		}
		dispatch(setDrawModeState({ enabled: !drawModeEnabled }));
		dispatch(toggleGroundMount({ groundMountEnabled: !!defaultToGroundMountFacet }));
	}

	return (
		<>
			<Button 
				id="draw-facet"
				style={{
					background: `${drawModeEnabled ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
					color: `${drawModeEnabled ? '#003CFF' : '#28373E'}`,
				}} 
				className="btn-tools btn-drawfacet"
				disabled={shouldDisable}
				onClick={handleDrawFacetClick}
				tooltipContent={drawModeEnabled ? '' : 'Draw Facet'}>
				<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.6153 10.3085L23.6043 16.9568H1L6.92018 7.64917H10.3077" stroke={`${drawModeEnabled ? '#003CFF' : '#64686A'}`} strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M19.742 2.29927L21.0413 1L23.1308 3.08948L21.8807 4.33956M19.742 2.29927L12.6834 9.35791L11.6387 12.4921L14.7729 11.4474L21.8807 4.33956M19.742 2.29927L21.8807 4.33956" stroke={`${drawModeEnabled ? '#003CFF' : '#64686A'}`} strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			</Button>
		</>
	);

}