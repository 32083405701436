import { useRefs } from 'contexts/RefContext';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from '../util';
import useScreenDims from './useScreenDims';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { BREAK_POINT_FOR_MOBILE_VIEW } from '../constants';

const useKonvaStageDimensions = ({ stageWrapRef }: { stageWrapRef: MutableRefObject<HTMLDivElement | null> }): Dimensions => {
	const { konvaRef } = useRefs();
	const screenDims = useScreenDims();
	const { imgHeight, imgWidth, org } = useSelector((state: RootState) => state.roofData.data);
	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);

	const [stageDims, setStageDims] = useState<Dimensions>({ height: imgHeight, width: imgWidth });

	const setCanvasDimensionsBasedOnScreen = useCallback(() => {
		const stageWrap = stageWrapRef?.current;
		const stage = konvaRef?.current;

		if (!stageWrap || !stage) return;

		let width = imgWidth, height = imgHeight;
		const imageRatio = imgWidth / imgHeight;

		const { width: screenWidth, height: screenHeight } = screenDims;
		const adjustDimsToScreenSize = !!org.setting.canEdit && !fullScreenEnabled;

		if (screenWidth >= BREAK_POINT_FOR_MOBILE_VIEW && stageWrap.clientWidth > imgWidth) {
			const rect = stageWrap.getBoundingClientRect();
			height = adjustDimsToScreenSize ? stageWrap.clientWidth / imageRatio : screenHeight - rect.top;
			width = fullScreenEnabled ? screenWidth: stageWrap.clientWidth;
		} else if (200 < screenWidth && screenWidth < 500 && !adjustDimsToScreenSize) {
			width = screenWidth;
		}

		stage.width(width);
		stage.height(height);
		setStageDims({ width, height });
	}, [fullScreenEnabled, imgHeight, imgWidth, konvaRef, org.setting.canEdit, screenDims, stageWrapRef]);

	const debouncedUpdateCanvasDimensions = useMemo(() => debounce(setCanvasDimensionsBasedOnScreen, 300),[setCanvasDimensionsBasedOnScreen]);

	useEffect(() => {
		debouncedUpdateCanvasDimensions();
	}, [debouncedUpdateCanvasDimensions]);

	return stageDims;
};

export default useKonvaStageDimensions;