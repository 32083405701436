import { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Group } from 'react-konva';

import SolarPanel from '../../Panel/SolarPanel';
import UserDefinedTemporaryPanels from 'components/tool/Panel/UserDefinedTemporaryPanels';

import { getExistingPanelsGroupID, KonvaGroupNames } from '../../utils';

type Props = {
	roofSegment: RasterRoofSegment;
}

function PanelsGroup({ roofSegment }: Props): JSX.Element {
	const { deletedPanels } = useSelector((state: RootState) => state.panelSlice.data);
	const deletedPanelIds = new Set(deletedPanels.map(p => p.panelId));
	const groupID = getExistingPanelsGroupID(roofSegment.id);
	return (

		<Group
			id={groupID}
			listening={true}
			name={KonvaGroupNames.existingPanelsGroup}
		>
			{
				roofSegment.panels.map((p) => {
					if (deletedPanelIds.has(p.id)) return null;
					return (
						<SolarPanel
							key={p.id}
							panelData={{ ...p, userMappedFacet: !!(p.userMapped || roofSegment.userMapped) }}
							panelId={p.id}
						/>
					);
				})
			}
			{/* unsaved panels added by user */}
			<UserDefinedTemporaryPanels roofSegmentId={roofSegment.id} />
		</Group>
	);
}
export default memo(PanelsGroup);