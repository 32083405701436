import { AxiosError } from 'axios';
import { deleteObstructions } from 'components/tool/Editor/ButtonDiv/Facet/DeleteObstructionBtn/api';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { errorResponseHandler } from 'store/api/AxiosError';

export default function useDeleteObstructionQuery() {
	const deletedObstructionIDs = useSelector((state: RootState) => state.toolNewPostions.data.deletedObstructionIDs);
	const { uuid } = useSelector((state: RootState) => state.roofData.data);

	return useMutation(
		'delete-obstructions',
		async () => {
			if (!deletedObstructionIDs?.length) return;
			await deleteObstructions(uuid, deletedObstructionIDs);
		},
		{
			onError(error: AxiosError) {
				const message = errorResponseHandler(error).message;
				toast.error(message);
			},
		}
	);
}