import { Dispatch, SetStateAction } from 'react';
import AnnualEnergyInput from '../AnnualEnergyInput';
import { PanelTitle } from '../style';

interface AnnualUsageProps {
	isMobileView: boolean;
	setError: Dispatch<SetStateAction<boolean>>;
}

export default function AnnualUsage({ setError, isMobileView }: AnnualUsageProps) {
	return <PanelTitle className='annual-usage-title' style={!isMobileView ? { width: '100%' } : {}}>
		<h4>Annual Usage</h4>
		<AnnualEnergyInput setError={setError} />
	</PanelTitle>;
}