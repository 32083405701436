import NoGapGroundMountToggle from 'components/tool/Editor/NoGapGroundMountToggle';
import GroundMountToggle from '../../../GroundMountToggle';
import UndoFacetButton from '../UndoButton';
import { StripDiv, LabelInfo } from './style';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CancelBtn from '../../Panel/CancelBtn';

export default function DrawModeStrip(){
	const { groundMountEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	return (
		<StripDiv>
			<LabelInfo>
				<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.6153 10.3085L23.6043 16.9568H1L6.92018 7.64917H10.3077" 
						stroke='#64686a' strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M19.742 2.29927L21.0413 1L23.1308 3.08948L21.8807 4.33956M19.742 2.29927L12.6834 
					9.35791L11.6387 12.4921L14.7729 11.4474L21.8807 4.33956M19.742 2.29927L21.8807 4.33956" 
					stroke='#64686a' strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				<label>Draw Facet</label>
			</LabelInfo>
			<GroundMountToggle />
			{
				groundMountEnabled && <NoGapGroundMountToggle/>
			}
			<UndoFacetButton />
			<CancelBtn strip={true} />
		</StripDiv>
	);
}