import { FlexDivWrap, ToolbarLabel } from './styles';

import { suggestNewPanelAlignment } from 'store/slices/RoofDataSlice/roofDataSlice';
import { negateEnergyProductionOfPanelsWhichAreStillInEditMode } from 'store/slices/EnergySlice/energySlice';
import { setEditState } from 'store/slices/ToolSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { useRefs } from 'contexts/RefContext';
import { useRef } from 'react';
import { resetAllTransformations } from 'components/tool/utils';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { EnergyState } from 'store/slices/EnergySlice/types';
export function EditButtonDiv() {

	const {
		roofIndexes, editModeEnabled, threeDModelEnabled, userHasModifiedRoofSegs
	} = useSelector((state: RootState) => state.toolNewPostions.data);
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { roofSegsFromWhichUserHasSelectedPanels } = useSelector((state: RootState) => state.roofData.data);
	const { annualInputEnergy } = useSelector((state: RootState) => state.energyData.data as EnergyState);
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } = useRefs();
	const toggleRef = useRef<HTMLInputElement>(null);
	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const someFacetIsStillInEditMode = roofSegsFromWhichUserHasSelectedPanels.filter(ind => roofIndexes.includes(ind));
	const diableEditToggle = queriesRunningInBackGround || threeDModelEnabled || showIrradiance || !!someFacetIsStillInEditMode?.length || activePanelMode;

	return (
		<FlexDivWrap>
			<div className='switchButton bg-transparent'>
				<ToolbarLabel className='toolbar-label'>Edit</ToolbarLabel>
     
				<div className="toggle-button-cover">
					<div className="button-cover">
						<div className="button b2" id="button-13">
							<input
								checked={editModeEnabled}
								ref={toggleRef}
								onChange={async () => {
									if (toggleRef.current) {
										const editEnabled = toggleRef.current.checked;
										toggleRef.current.checked = editEnabled;
										dispatch(setEditState({ enabled: editEnabled }));

										if (!editEnabled && konvaRef?.current) {
											if (userHasModifiedRoofSegs) {
												await dispatch(suggestNewPanelAlignment({ energy: annualInputEnergy }));
											}
											dispatch(negateEnergyProductionOfPanelsWhichAreStillInEditMode(roofIndexes));
											resetAllTransformations(konvaRef.current);
										}
									}
								}}
								type="checkbox"
								className="checkbox"
								disabled={diableEditToggle}
							/>
							<div className="knobs">
								<span></span>
							</div>
							<div className="layer"></div>
						</div>
					</div>
				</div>
			</div>
		</FlexDivWrap>);

}