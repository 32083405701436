import { useRefs } from 'contexts/RefContext';
import Konva from 'konva';
import { useMemo } from 'react';
import { Circle, Group, Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setDeletedObstructionIDs } from 'store/slices/ToolSlice';
import { KonvaGroupNames } from './tool/utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

const calculateCentroid = (points: number[]) => {
	let sumX = 0, sumY = 0;
	const numPoints = points.length / 2;

	for (let i = 0; i < points.length; i += 2) {
		sumX += points[i];
		sumY += points[i + 1];
	}

	return {
		x: sumX / numPoints,
		y: sumY / numPoints,
	};
};

export default function Obstructions() {

	const { obstructions } = useSelector((state: RootState) => state.roofData.data);
	const { currentActiveFacetEditMode, deletedObstructionIDs, moveStage, editModeEnabled }
		= useSelector((state: RootState) => state.toolNewPostions.data);
	const isIrradianceOn = useSelector((state: RootState) => state.Irradiance.data.showIrradiance);
	const scale = useKonvaImageScale();
	const dispatch = useDispatch();
	const { konvaRef } = useRefs();

	const shuldListenClickEvents = !moveStage && currentActiveFacetEditMode === 'DELETE_OBSTRUCTION_MODE';

	const obstructionsList = useMemo(() => (obstructions.map((obstruction) => {
		const transformedCoordinates = obstruction.coordinates.flat().map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y);
		const centroid = obstruction.assessment === 'RAW_LARGE' ? calculateCentroid(transformedCoordinates) : null;
		return { ...obstruction, coordinates: transformedCoordinates, centroid };
	})), [scale, obstructions]);

	function handleClick(evt: Konva.KonvaEventObject<MouseEvent | Event>) {
		dispatch(setDeletedObstructionIDs({ obstructionID: evt.target.id() }));
		if (konvaRef?.current)
			konvaRef.current.container().style.cursor = 'default';
	}

	return (
		<Group
			name={KonvaGroupNames.obstructionsGroup}
			visible={editModeEnabled && !isIrradianceOn}
			listening={shuldListenClickEvents}
			onClick={handleClick}
			onTap={handleClick}
			onMouseEnter={() => {
				if (konvaRef?.current)
					konvaRef.current.container().style.cursor = 'pointer';
			}}
			onMouseLeave={() => {
				if (konvaRef?.current)
					konvaRef.current.container().style.cursor = 'default';
			}}
		>
			{
				obstructionsList.map((obstruction) => {
					if (deletedObstructionIDs.includes(obstruction.id)) return null;

					// SHow filled circle
					if (obstruction.centroid) {
						return (<Circle
							id={obstruction.id}
							key={obstruction.id}
							x={obstruction.centroid.x}
							y={obstruction.centroid.y}
							radius={3}
							fill={'red'}
							strokeWidth={2}
							stroke='red'
							closed={true}
							hitFunc={(context, shape) => {
								context.beginPath();
								const hitRadius = shape.getAttr('radius') + 10;
								context.arc(0, 0, hitRadius, 0, Math.PI * 2, false);
								context.fillStrokeShape(shape);
							}}

						/>);
					}
					//normal 
					return (
						<Line
							id={obstruction.id}
							key={obstruction.id}
							points={obstruction.coordinates}
							strokeWidth={1}
							stroke='red'
							closed={true}
							hitFunc={(context, shape) => {
								const hitRadius = 5;
								const points = (shape as Konva.Line).points();
								context.beginPath();
								for (let i = 0; i < points.length; i = i + 2) {
									const x = points[i], y = points[i + 1];
									context.arc(x, y, hitRadius, 0, Math.PI * 2, false);
								}
								context.fillStrokeShape(shape);

							}}

						/>
					);
				})
			}
		</Group>
	);
}