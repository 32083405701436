import styled from 'styled-components';

export const ModalBoxWrap = styled.div`
		position: absolute;
    top: 1.25rem;
    left: 50%;
    // right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
		z-index: 9;
		overflow: hidden;
    transform: translateX(-50%);
    white-space: nowrap;
		
		@media (max-width: 540px){
		 top: 1.25rem;
		 left: 5%;
		 transform: translateX(0);
      justify-content: flex-center;
		}
		&.fullscreen{
		max-width: 100%;
		}
`;
export const ModalBox = styled.div`
		display: flex;
		align-items: center;
		pointer-events: all;
		border: 1px solid rgb(228,228,228);
		border-radius: 91.4939px;
		overflow: hidden;
	
		padding: .275rem 0.813rem;
		background: #fff;

		.btn-drawfacet{
			background: transparent !important;
			margin-right: 10px;
			height: 32px;
			min-height:30px;
			padding-left:5px;

			.buttonIcon{
				display: none;
			}
		}
		#delete-obstruction-mode, #undo, #redo{
			background: transparent !important;
			height: 32px;
			min-height:30px;
		}
		#delete-obstruction-mode{
			border-right:none;

			.buttonIcon{
				display: none;
			}
		}
		#add-panel{
			background: transparent !important;
			height: 32px;
			min-height:30px;
			padding:0 0.65rem 0 0.5rem;
			.buttonIcon{
				display:none;
			}
		}
			
`;