import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import CustomToggle from 'components/Basic/CustomToggle/CustomToggle';
import { FlexDivRow } from './style';
import { toggleGroundMount } from 'store/slices/ToolSlice';

export default memo(function GroundMountToggle() {
	const { groundMountEnabled, newlyCreatedFacets } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch<AppDispatch>();
	const toggleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(toggleGroundMount({ groundMountEnabled: event.target.checked}));
	};
	const disabled = !!(Object.keys(newlyCreatedFacets).length) ;
	return (
		<FlexDivRow>
			<CustomToggle
				title='Flat'
				checked={groundMountEnabled}
				onChange={toggleChecked}
				disabled={disabled}
			/>
		</FlexDivRow>
	);
});