import styled, { Interpolation } from 'styled-components';
type props = {
  styles?: Interpolation<React.CSSProperties>
	fullScreenEnabled?:boolean
}
export const MonthButtonsWrap= styled.div<props>`
	position: absolute;
	z-index: 1;
	display: flex;
	flex-direction: column;
	top: 20px;
	right: 10px;
	gap: 0.25rem;
	height: 100%;
	// justify-content: ${(props) => (props.fullScreenEnabled ? 'start' : 'center')};
	justify-content: start;

	.months-btn{
	padding: 0.45rem 0.5rem;
	 min-height: 32px;
	}
`
;