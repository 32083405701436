import styled from 'styled-components';

const ButtonStyle = styled.button`
	background: var(--primary);
  appearance: none;
  border: 1px solid var(--primary);
	padding: 0.45rem .72rem;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  font-size: 0.922rem;
  cursor: pointer;
	position: relative; // Added for positioning the loader
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Inter', sans-serif;
	gap:0.5rem;
	min-height: 36px;
  min-width: 36px;

	&.run-ai-btn{
		border-radius: 40px;
		background: var(--secondary);
		border: 1px solid var(--secondary);
		color: var(--highlight);	
		padding-inline: 1.15rem;
		padding-block: 0.584rem;
	}

	&#settings{
			border: 1px solid #c7cacf;
			font-size: 0.938rem;
			height: 38px;
			min-width: 39px;
			border-radius: 100%;
			background: rgb(255, 255, 255);
			color: #64686a;
			padding: 7.5px 8px;
			@media(max-width:860px){
				padding: 6px 8px;
				height: 34px;
				min-width: 36px;
				svg{
					width:16px;
					height: 16px;
				}
			}
	}
	&.btn-3d{
			border-radius: 20px;
			gap: .35rem;
			padding: 0.5rem 0.72rem;
			min-height: 35px;
			font-size: 0.938rem;
			
			@media(min-width:981px){
			 min-width: 70px;
			}
			 @media (max-width: 860px){
			 	padding: 0.48rem 0.45rem;
				svg{
						position: relative;
						left: 1px;
				 }
			 }
			@media (max-width: 540px){
			 	padding: 0.48rem 0.35rem;
			 }
	}	
	&.fullscreenviewVisible{
			border-radius: 100%;
			background: #fff;
			border-color: #C7CACF;
			color: #64686A;
			padding: 6px 5px 6.5px 5px;
			min-width: 39px;
			min-height: 38px;
	}
	&.cancel-btn{
		background: #f9f9f9;
		border: 1px solid #d3d3d3;
		color: #64686A;
		font-size: 0.875rem;
		padding: .525rem 0.425rem;
		height: 40px;
		min-width: 40px;
		border-radius: 20px;
		box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 4px;
		@media (max-width: 840px){
			height: 38px;
			min-width: 38.5px;
    }
			&.strip-cancel{
				border: none;
				height:32px;
				min-height: auto;
				min-width: auto;
				padding: 0.325rem 0.325rem;
				background: transparent;
				box-shadow: none;
				&:hover{
					#cancel-path
							stroke:rgb(245, 129, 129) !important;
					}
				}
				}
			}
	}
	&.btn-done{
			border: 1px solid rgba(0, 60, 255, 0.5);
			font-size: 0.875rem;
			padding: 5px 8px 4px;
			height: 40px;
			min-width: 82px;
			border-radius: 20px;
			box-shadow: rgba(100,100,100,0.12) 0px 2.25911px 4px;

			label{
				line-height:1.5;
			}

			@media (max-width: 840px){
			height: 38px;
			min-width: 74px;

				svg{
					width: 19px;
					height:19px;
				}
			}
	}	
	&.btn-tools{
		border: 0px;
		border-left: 0px;
		color: #28373E;
		font-size: 0.938rem;
		border-radius: 0px;
		padding: 0px 8px;
		transition: all 2s ease;
		height: 40px;
		width: auto;
		min-width: 40px;
		@media(max-width:860px){
			height: 38px;
		}
	}		
	&#move-panel{
		border-right: 1px solid #E4E4E4;
		min-width: 42px;
		@media(max-width:860px){
			height: 38px;
			svg{
				width: 17.6px;
				height: 20px;
			}
		}
	}		
	&#delete-panel{
		border-right: 0px;
		padding-right: 10px;
		@media(max-width:860px){
		 height: 38px;
			svg{
				width: 18.40px;
				height: 20px;
			}
		}
	}
	&#add-panel{
		border-right: 1px solid #E4E4E4;
		padding: 0.125rem 0.5rem 0.125rem 0.725rem;
		@media(max-width:860px){
		 height: 38px;
			svg{
				width: 19.1px;
				height: 20px;
			}
		}
	}
	&#delete-obstruction-mode{
			// border-right: 1px solid #e4e4e4;
			padding: 0px 0.938rem 0px .625rem;
			transition: all 2s ease;
			@media(max-width:860px){
			height: 38px;
			svg{
				width: 20px;
				height: 20px;
			}
		}
	}
	&#recalculate-layout{
		background: #fff;
		border-right: 1px solid #e4e4e4;
		width: 40px;
			@media(max-width:860px){
			height: 38px;
			svg{
				width: 20px;
				height: 20px;
			}
		}
	}	
	&#delete{
		border-right: 1px solid #e4e4e4;
		width: 40px;
		@media(max-width:860px){
		 height: 38px;
			svg{
				width: 21px;
				height: 20px;
			}
		}
	}
	&#undo-remove-shading{
		background: #fff;
		border-right: 1px solid #E4E4E4;
		width: 40px;
		@media(max-width:860px){
		 height: 38px;
			svg{
				width: 17px;
				height: 21px;
			}
		}
	}
	&#remove-shading{
		background: #fff;
		border: 0px;
		border-right: 1px solid #E4E4E4;
		width: 40px;

		@media(max-width:860px){
		  height: 38px;
			svg{
				width: 17px;
				height: 21px;
			}
		}
	}
	&.facetundo{
		padding: 0px 10px 0 6px;
		min-width: 36px;
		transform: scale(-1,1);
		// background: #fff;
		color: transparent;
		border:none;
		border-right: 1px solid #E4E4E4;
		min-height: 27px;

		&#undo{
			height: 27px;
			min-height: 27px;
			padding: 0px 10px 0 6px;
			// background:#fff !important;
			// padding: .25rem 1.5rem;
			// border-radius:25px;
		}
		
		svg{
			position:relative;
			top: -0.5px;
		}
  }	
	&#change-orientation{
			background: #fff;
			border-right: 1px solid #E4E4E4;
			width: 40px;

		@media(max-width:860px){
		  height: 38px;
			svg{
				width: 20px;
				height: 20px;
			}
		}
	}
	&.btn-drawfacet{
		border-right: 1px solid #E4E4E4;
		padding: 0px 10px;

		@media(max-width:860px){
		  height: 38px;
			svg{
				width: 23px;
				height: 17px;
			}
		}
	}
	&#undo{
			background: #fff;
			border:none;
			border-right: 1px solid #e4e4e4;
			border-left: 0px;
			color: #003CFF;
			padding: 0px 10px;
			transform: scale(-1, 1);
			@media(max-width:860px){
		 	 height: 30px;
			 padding-left:6px;
				svg{
					width: 20px;
					height: 20px;
				}
      }
		&.move-undo{
				border: 0px;
				border-right: 1px solid #E4E4E4;
				border-left: 0px;
				padding: 0px .65rem;
				min-width: 38px;
				border-radius: 0px;
				transform: scale(-1, 1);
				position: relative;
				right: -1px;
				height:30px;
		}
	}	
	&#redo {
			background: #fff;
			border: 0px;
			border-left: 1px solid #e4e4e4;
			border-right: 0px;
			color: #003CFF;
			padding: 0px 4px 0 9px;

			&.delete-obstruction-redo{
				padding-left:10px;
			}
			@media(max-width:860px){
		 	 height: 30px;
				svg{
					width: 20px;
					height: 20px;
				}
      }	
			&.move-redo{
					padding: 0 0.625rem 0 0.65rem;
					height: 40px;
					min-width: 38px;
					border-radius: 0px;
					height:30px;
			}
	}

	&.months-btn	{
			font-weight: 500;
			font-size: 0.825rem;
			border-radius: 20px;
			@media(max-width:860px){
					min-height: 32px;
			}
	}		
	&.recommend-btn{
				border-radius: 20px;
				color: #64686A;
				background: #fff;
				border: 1px solid #C7CACF;
				white-space: nowrap;
				gap: 0.35rem;
				padding: 0.5125rem .82rem;

			@media(max-width:1280px){
				min-width: 38px;
				padding: 0.5125rem .25rem;
				svg{
					position:relative;
					left:2px;
				}
					label{
					display:none;
					}
			}
	}
	&.irradiance-btn{
		padding: 0.5125rem .72rem;
		border-radius: 20px;
			@media(max-width:1024px){
				padding: 0.5125rem .45rem;
					label{
						display:none;
					}
			}
				@media(max-width:540px){
				padding: 0.5125rem .35rem;
			}
	}
	&.debug-btn{
		background: #FFF;
		color: #FFF;
		border-color: #C7CACF;
		border-radius:20px;
		padding: 0.5rem .5rem;
		min-width:38px;
		height: 38px;
	}
  &:hover {
    opacity: .9;
  }

	&:disabled {
		cursor: not-allowed !important;
		opacity: .7;
	}
	&.close-icon{
		&:hover {
			background: #fff;
		}
	}	
	@media (max-width:1325px){
	// font-size: 0.856rem;
	gap:0.35rem;
	}

	.showLoader&:before {
		content: '';
		display:block;
		position:absolute;
		height:100%;
		width:100%;
		top:0;
		left:0;
		background-color:inherit;
		z-index:1;
		opacity: .45
	}
		
	.showLoader&:after {
		content: '';
		border: 4px solid red;
		border-color:  rgb(32 37 39 / 42%) rgb(253, 251, 247) #fdfbf7 !important;
		border-radius: 50%;
		border-top: 4px solid white;
		animation: spin 2s linear infinite;
		position: absolute;
		transform: translate(-50%, -50%);
		height: 50%;
		z-index: 10;
    aspect-ratio: 1/1;
		
	
		@-webkit-keyframes spin {
			0% {
				-webkit-transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
			}
		}
	
		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
	}

`;

export { ButtonStyle };