import Button from 'components/Basic/Button';
import { DESIGN_MODES } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setIrrandiance } from 'store/slices/IrradianceSlice';
import { setEditState } from 'store/slices/ToolSlice';

export function IrradianceToggle() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { mode } = useSelector((state: RootState) => state.roofData.data);
	const dispatch = useDispatch();

	function handleIrradianceClick() {
		// dispatch(setEditState({ enabled: false }));
		dispatch(setIrrandiance({ showIrradiance: !showIrradiance }));
	}

	if (mode != DESIGN_MODES.DEFAULT) {
		return null;
	}

	return <Button style={{
		color: showIrradiance ? '#28373E' : '#64686A',
		background: `${showIrradiance ? 'var(--secondary)' : '#fff'}`,
		border: `${showIrradiance ? '1px solid var(--secondary)' : '1px solid #C7CACF'}`,
	}}
	onClick={handleIrradianceClick}
	disabled={threeDModelEnabled}
	className={'btnFont0 btnHover irradiance-btn ' + `${showIrradiance ? ' active' : ''}`}>
		<svg width="18" height="19" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="9.43194" cy="10.0048" rx="3.48858" ry="3.50336" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" />
			<ellipse cx="9.43194" cy="10.0048" rx="3.48858" ry="3.50336" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" />
			<path d="M15.2988 10.2814H17.888" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M15.2988 10.2814H17.888" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M1 10.3154H3.58918" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M1 10.3154H3.58918" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.2832 15.8447L9.2832 18.4449" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.2832 15.8447L9.2832 18.4449" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.50586 1.55554L9.50586 4.15569" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.50586 1.55554L9.50586 4.15569" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13.3867 14.3562L15.2175 16.1948" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13.3867 14.3562L15.2175 16.1948" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3.25195 4.22534L5.08278 6.06393" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3.25195 4.22534L5.08278 6.06393" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5.21484 14.0181L3.38402 15.8567" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5.21484 14.0181L3.38402 15.8567" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M15.4355 4.07275L13.6047 5.91134" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M15.4355 4.07275L13.6047 5.91134" stroke={`${showIrradiance ? '#28373E' : '#64686a'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
		<label>Irradiance</label>
	</Button>;

}