import { useSelector } from 'react-redux';
import { StripDiv } from '../Panel/AddPanelButton/style';
import { RootState } from 'store';

export default function ShowCurrentAzimuth(){
	const { selectedRoofAzimuth } = useSelector((state: RootState) => state.toolNewPostions.data);
	return (
		<StripDiv style={{color: '#2E384D', lineHeight:'1.5', fontSize:'1rem' }}>
			Azimuth : <span style={{ color:'#003CFF', fontSize:'1.15rem', fontWeight:'600', marginLeft:'5px' }}>{selectedRoofAzimuth}
				<sup>°</sup></span>
		</StripDiv>
		
	);
}