import { AxiosError } from 'axios';
import { addNewPanels } from 'components/tool/Editor/ButtonDiv/api';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { errorResponseHandler } from 'store/api/AxiosError';

export default function useAddPanelsQuery() {
	const newlyAddedPanels = useSelector((state: RootState) => state.panelSlice.data.newlyAddedPanels);
	const { uuid, panel } = useSelector((state: RootState) => state.roofData.data);

	return useMutation(
		'add-panels',
		async () => {
			if (!newlyAddedPanels.length) throw new Error('E_NO_PNEL_TO_ADD');
			await addNewPanels(newlyAddedPanels, panel.key, uuid);
		},
		{
			onError(error: AxiosError) {
				if (error.message === 'E_NO_PNEL_TO_ADD') return;
				const message = errorResponseHandler(error).message;
				toast.error(message);
			},
		});
}