
import styled from 'styled-components';
import { Option } from '.';
import Select from 'react-select';

export const DivMapping = styled.div`
	display:flex;
	align-items:center;
	position: relative;
`;

export const SVGMap = styled.svg`
	position: relative;
	margin-right:7px;
	color:#a1a1a1;
	margin-left: 2px;
`;

export const SelectList = styled.select`
	font-size: 0.938rem;
	background-color: #fff;
	color: #64686A;
	font-family: Inter;
	font-weight: 500;
	background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFF;
	border: 1px solid #C7CACF;
	box-shadow:none;
	border-radius:40px;
	height: 40px;
	appearance: none;
		&:focus-visible {
			outline: 1px solid #ccc;
		}
	@media (max-width: 1325px) {
		padding: 6px 34px 6px 36px;
		font-size: 0.856rem;
	}
	@media (max-width: 936px){
		height:34px;
		
	}
	
`;

export const SVGDown = styled.svg`
	position: absolute;
	color:#a1a1a1;
	right: 16px;
	pointer-events:none;
`;
export const OptionGroup = styled.optgroup`
	font-size: 0.938rem;
	color: #28373e;
	font-family: 'Inter';
	font-weight: 500;
`;

export const StyledSelect = styled(Select<Option>)`

&.imagary-provider-select{
	.cus-select__control{
		border: 1px solid #C7CACF;
		box-shadow:none;
		border-radius:40px;
		flex-wrap: nowrap;
		font-size: 0.925rem;
	}
	.cus-select__value-container{
		padding: 7px 4px 7px 8px !important;	
		min-width:156px;
		span{
			font-size: 0.925rem;
		}
	}
	.cus-select__indicator-separator{
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		background-color: transparent;
	}
	.cus-select__dropdown-indicator{
    color: #595A5A;
		padding: 6px 12px 6px 2px;

		svg{
			height: 19px;
			width: 19px;
		}
  }
	
	.cus-select__single-value{
	  color: #2E384D;
		display: flex;
    align-items: center;
		font-size: 0.925rem;
	}
}
	.cus-select__menu{
		margin:0;
		z-index:10;
	}
  .cus-select__menu-list{
		padding: 0px;
		border-radius: 4px;
	}
	.cus-select__group-heading{
		line-height:1.4;
		padding: 4px 11px
	}
		.cus-select__group{
			padding-top:5px;
			padding-bottom:0px;
		}
	width: 100%;
	font-size: .925rem;
	margin-top: 2px;
	color: #2E384D;

`;