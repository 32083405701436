import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ModalBox, ModalBoxWrap } from './style';

export default function ModeStrip({children}: { children: React.ReactNode }) {

	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);

	return (
		<ModalBoxWrap className={`${fullScreenEnabled ? ' fullscreen' : ''}`}>
			<ModalBox>
				{children}
			</ModalBox>
		</ModalBoxWrap>
	);
}