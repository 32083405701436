import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setAddPanel, togglePanelModal } from 'store/slices/PanelSlice';
import AddPanelModal from './AddPanelModal/index';
import { toggleAddPanelModalState } from 'store/slices/AddPanelModalSlice/addPanelModalSlice';
import CancelAddPanelModal from './CancelAddPanelModal';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function AddPanelButton() {
	const dispatch = useDispatch<AppDispatch>();
	const { activePanelMode, enableAddPanel, newlyAddedPanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { hadTransition, currentActiveFacetEditMode } = useSelector((state: RootState) => state.toolNewPostions.data);
	const queriesRunningInBackground = useQueriesRunningInBackGround();
	const shouldDisable = queriesRunningInBackground || !!currentActiveFacetEditMode ||
		activePanelMode && !enableAddPanel || hadTransition;

	function handleClick() {
		if (enableAddPanel && newlyAddedPanels.length > 0) {
			dispatch(togglePanelModal({ targetModal: 'CANCEL_ADD_PANEL', val: true }));
			return;
		}
		if (!enableAddPanel) {
			dispatch(toggleAddPanelModalState({ value: true }));
		}

		dispatch(setAddPanel({ shouldEnable: !enableAddPanel }));

	}

	const handleModalConfirm = () => {
		dispatch(setAddPanel({ shouldEnable: true }));
		console.log(newlyAddedPanels);
	};

	const handleModalCancel = () => {
		dispatch(setAddPanel({ shouldEnable: false }));
		console.log(newlyAddedPanels);
	};

	return (
		<>
			<Button
				className="btn-tools"
				style={{
					background: `${enableAddPanel ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
				}}
				id="add-panel"
				onClick={handleClick}
				disabled={shouldDisable}
				tooltipContent={enableAddPanel ? '' : 'Add Panels'}
			>
				<svg width="21" height="22" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.80246 21.631H1V1H16.4732V7.44718" stroke={`${enableAddPanel ? '#003CFF' : '#64686A'}`} strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M21.6309 16.4731L8.7365 16.4731M15.1837 10.0259L15.1837 22.9202" stroke={`${enableAddPanel ? '#003CFF' : '#64686A'}`} strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			</Button>
			<AddPanelModal
				onConfirm={handleModalConfirm}
				onCancel={handleModalCancel} />
			<CancelAddPanelModal />
		</>
	);

}