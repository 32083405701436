import axios from 'axios';
import { getToken, createApiUrl } from '../../../../util';

async function changeFacetOrientation(selectFacetId: string, 
	uuid: string, panelKey: string, orientation: string, userMapped: boolean) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const body = {
		selectedRoofSegmentId: selectFacetId,
		orientation,
		uuid,
		panelKey,
		userMapped
	};

	const apiUrl = '/designs/rotate-roof-segments';
	const { data } = await axios.post(createApiUrl(apiUrl), body, config);
	return data;
}

export {
	changeFacetOrientation
};