import React, { useEffect, useRef } from 'react';
import { useRefs } from 'contexts/RefContext';
import RasterImage from './RasterImage';
import { Group } from 'react-konva';
import { Circle } from 'konva/lib/shapes/Circle';
import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { setAzimuthOrPitchForNewlyCreatedNrelFacets, setNewlyCreatedFacets, setUnfinalizedFacet } from 'store/slices/ToolSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { KonvaEventObject } from 'konva/lib/Node';
import { setDropdownState } from 'store/slices/EditFacetModalSlice';
import { calculateCentroidOfPolygon, calculateArrowPoints, KonvaGroupNames } from '../utils';
import { DESIGN_MODES, KONVA_FACET_GROUP, SEMI_TRANPARENT_BLACK_PANEL } from '../../../constants';

const distance = (a: number[], b: number[]) => Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2);

const lineConfig = {
	stroke: 'rgba(0,0,0,0.4)',
	strokeWidth: 3,
	fill: 'rgba(0,0,0,0.4)',
};

type DrawArrowProps = {
	polPoints: number[][];
	center: Vector2d;
	userFacetGroup: Konva.Group;
	konvaRef: React.RefObject<Konva.Stage>;
	dispatchCallback: (id: string, azimuth: number) => void,
};

type ArrowData = {
	angle: number;
	groupRefId: string;
	selected: boolean;
	center: Konva.Vector2d;
};
// Function to draw arrows with updated types
function DrawArrows({ polPoints, center, userFacetGroup, konvaRef, dispatchCallback }: DrawArrowProps) {
	// Function to create an arrow
	const createArrow = (points: number[], customData: ArrowData, rectConfig: Konva.RectConfig): Konva.Group => {
		const rect = new Konva.Rect({
			...rectConfig,
			visible: true,
			stroke: SEMI_TRANPARENT_BLACK_PANEL,
			strokeWidth: 1,
			fill: SEMI_TRANPARENT_BLACK_PANEL,
			cornerRadius: [0, 2, 2, 0]
		});

		const arrow = new Konva.Arrow({
			points,
			pointerLength: 4,
			pointerWidth: 4,
			fill: 'white',
			stroke: 'white',
			strokeWidth: 1,
			hitStrokeWidth: 20,
		});

		const group = new Konva.Group({ customData, name: 'azimuth_selector' });
		group.add(rect);
		group.add(arrow);

		return group;
	};

	// Function to handle arrow click event
	const handleArrowClick = (arrowWithRect: Konva.Group, data: ArrowData) => {
		arrowWithRect.on('click', (e) => {
			e.cancelBubble = true;
			const { angle, groupRefId } = data;
			dispatchCallback(groupRefId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0], angle);
			selectArrow(arrowWithRect, userFacetGroup);
		});

		arrowWithRect.on('mouseenter', () => {
			if (arrowWithRect.attrs?.customData?.selected) return;
			toggleArrowHighlight(arrowWithRect, true);
		});

		arrowWithRect.on('mouseleave', () => {
			if (arrowWithRect?.attrs?.customData?.selected) return;
			toggleArrowHighlight(arrowWithRect, false);
		});
	};

	// Function to select arrow and handle visibility
	const selectArrow = (arrowWithRect: Konva.Group, group: Konva.Group) => {
		const data = arrowWithRect.getAttr('customData') as ArrowData;
		arrowWithRect.setAttr('customData', { ...data, selected: true, });
		toggleArrowHighlight(arrowWithRect, true);

		group.children?.forEach((child) => {
			if (child.getClassName() === 'Group' && child.hasName('azimuth_selector') && child._id !== arrowWithRect._id) {
				const childData = child.getAttr('customData') as ArrowData;
				if (childData?.selected) {
					child.setAttr('customData', { ...childData, selected: false });
					toggleArrowHighlight(child as Konva.Group, false);
				}
			}
		});
	};

	//Function to toggle Highlight of arrows
	const toggleArrowHighlight = (arrowWithRect: Konva.Group, highlight = false) => {
		const rectColor = highlight ? '#ffc005' : SEMI_TRANPARENT_BLACK_PANEL;
		const arrowColor = highlight ? 'black' : 'white';
		if (!arrowWithRect.getChildren()?.length) return;
		const [rect, arrow] = arrowWithRect.getChildren() as [Konva.Rect, Konva.Arrow];
		rect.stroke(rectColor);
		rect.fill(rectColor);
		arrow.stroke(arrowColor);
		arrow.fill(arrowColor);
	};

	// Iterate through points and create arrows
	for (let i = 0; i < polPoints.length; i++) {
		const j = (i + 1) % polPoints.length;
		const { arrow: ap, rectangle } = calculateArrowPoints([polPoints[i], polPoints[j]], center);

		const arrowData: ArrowData = {
			angle: ap.angle,
			groupRefId: userFacetGroup.id(),
			selected: false,
			center,
		};

		const arrowWithRect = createArrow([...ap.outwardPoints[0], ...ap.outwardPoints[1]], arrowData, rectangle);

		// Handle the first arrow as the center arrow
		if (i === 0) {
			const { angle, groupRefId } = arrowData;
			dispatchCallback(groupRefId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0], angle);
			selectArrow(arrowWithRect, userFacetGroup); // This ensures the first arrow is selected and blue
		}

		handleArrowClick(arrowWithRect, arrowData);
		userFacetGroup.add(arrowWithRect);
	}
}

type Props = {
	showAzimuthArrows: boolean;
}
export default function PanelDrawer(props : Props) {
	const { editModeEnabled, drawModeEnabled, moveStage, newlyCreatedFacets, 
		groundMountEnabled, noGapGroundMountEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { mode } = useSelector((state: RootState) => state.roofData.data);
	const { konvaRef } = useRefs();
	const groupRef = useRef<Konva.Group | null>(null);
	const userFacetGroup = useRef<Konva.Group | null>(null);
	const isDrawing = useRef<boolean>(false);
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const dispatch = useDispatch<AppDispatch>();

	const canDrawFacet = editModeEnabled && drawModeEnabled && !moveStage;

	useEffect(() => {
		const panelDrawerChildren = groupRef.current?.children ?? [];

		if (panelDrawerChildren.length > 1) {
			for (const child of panelDrawerChildren) {
				if (child instanceof Konva.Image) continue;
				child.destroy();
			}
			userFacetGroup.current = null;
		}
	}, [drawModeEnabled, konvaRef]);

	function dispatchNewlyCreatedFacets(facetPoints: number[][]) {
		dispatch(setNewlyCreatedFacets({
			newlyCreatedFacet: {
				hullCoords: facetPoints,
				konvaPolygonId: userFacetGroup.current!.id().split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0],
				isGroundMount: groundMountEnabled,
				noGapGroundMount: noGapGroundMountEnabled, 
			}
		}));
	}

	function makeOtherPolygonsInvisbleWhileUserIsDrawing(opacity: number) {
		groupRef.current?.children?.forEach(c => {
			if (c instanceof Konva.Image) return;
			c.opacity(opacity);
		});
	}

	function makePolygon() {
		if (!konvaRef?.current) return;
		const { x, y } = konvaRef.current.getRelativePointerPosition() as Vector2d;

		if (groupRef.current?.children?.length) {
			//update drwaing state when no userfacetgroup or all are completed
			const children = groupRef.current.children;

			if (children.length === 1) {
				isDrawing.current = false;
			} else {
				const lastChild = children[children.length - 1];
				isDrawing.current = !lastChild?.attrs?.closed;
			}
		}

		if (isDrawing.current === false) {
			userFacetGroup.current = null;
			//Create new Facet Group having circles and lines
			const uuid = crypto.randomUUID();
			userFacetGroup.current = new Konva.Group({
				name:  KonvaGroupNames.inProgressUserDefinedFacetGroup,
				id: uuid + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME
			});
			const previewLine = new Konva.Line({ name: 'preview-line', ...lineConfig, points: [] });
			const facetLine = new Konva.Line(
				{
					...lineConfig,
					points: [],
					id: uuid + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.LINE_ID_SUFFIX,
				});

			userFacetGroup?.current?.add(facetLine);
			userFacetGroup?.current?.add(previewLine);
			groupRef.current?.add(userFacetGroup.current as Konva.Group);
		}

		const facetLine = userFacetGroup?.current?.children?.[0] as Konva.Line;
		const previewLine = userFacetGroup?.current?.findOne('.preview-line') as Konva.Line;
		const points = facetLine?.points() ?? [];

		if (points.length > 2 && distance([x, y], [points[0], points[1]]) < 10) {
			//complete the facet
			facetLine.closed(true);
			userFacetGroup.current?.setAttr('closed', true);
			previewLine?.remove();

			const pts = points.slice();
			const p: number[][] = [];
			pts.forEach((v, i) => {
				const ind = Math.floor(i / 2);
				if (i % 2 === 0) p.push([v]);
				else p[ind].push(v);
			});
			makeOtherPolygonsInvisbleWhileUserIsDrawing(1);
			dispatchNewlyCreatedFacets(p);
			if (props.showAzimuthArrows) {
				DrawArrows({
					polPoints: p,
					center: calculateCentroidOfPolygon(pts),
					userFacetGroup: userFacetGroup.current!,
					konvaRef,
					dispatchCallback: (id, azimuth) => {
						dispatch(setAzimuthOrPitchForNewlyCreatedNrelFacets({
							facetId: id,
							azimuthDegrees: azimuth,
						}));
					}
				});
			}
			dispatch(setUnfinalizedFacet({ id: userFacetGroup.current!.id().split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] }));
			isDrawing.current = false;
			return;
		}

		points.push(+x, +y);
		addPoint(x, y);
		facetLine.points(points);
		facetLine.on('contextmenu', handlePolygonClick);
		facetLine.on('touchstart', handleTouchStart);
		facetLine.on('touchend', handleTouchEndOrCancel);
	}

	function addPoint(x: number, y: number) {
		userFacetGroup.current?.add(new Circle({
			x: x,
			y: y,
			radius: 3,
			fill: 'blue',
			stroke: 'black',
			strokeWidth: 1
		}));
		isDrawing.current = true;
	}

	function handleMouseMove() {
		if (!konvaRef?.current || !userFacetGroup?.current) return;
		const { x, y } = konvaRef.current.getRelativePointerPosition() as Vector2d;
		const facetLine = userFacetGroup?.current?.children?.[0] as Konva.Line;
		const previewLine = userFacetGroup?.current?.findOne('.preview-line') as Konva.Line;
		const points = facetLine?.points() ?? [];
		if (points.length >= 2) {
			const startX = points[points.length - 2], startY = points[points.length - 1];
			previewLine?.points([startX, startY, x, y]);
			previewLine?.visible(true);
		}	
	}

	function handlePolygonClick(e: KonvaEventObject<MouseEvent>) {
		e.evt.preventDefault();
		const lineId = e.target.getAttr('id');
		dispatch(setUnfinalizedFacet({ id: lineId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] }));
		dispatch(setDropdownState({ open: true, position: { x: e.evt.clientX, y: e.evt.clientY } }));
	}

	function handleTouchStart(e: KonvaEventObject<TouchEvent>) {
		clearTimeout(longTouchTimerRef.current);
		longTouchTimerRef.current = setTimeout(() => {
			const lineId = e.target.getAttr('id');
			console.log('target id', lineId);
			dispatch(setUnfinalizedFacet({ id: lineId.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0] }));
			dispatch(setDropdownState({ open: true, position: { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY } }));
		}, 2000);
	}

	function handleTouchEndOrCancel() {
		clearTimeout(longTouchTimerRef.current);
	}
	const totalCreateFacets = Object.keys(newlyCreatedFacets);

	return (

		<Group
			ref={groupRef}
			name={'panel-drawer'}
			listening={canDrawFacet}
			onClick={(e) => {
				if (!canDrawFacet) return;
				if (mode !== DESIGN_MODES.DEFAULT && totalCreateFacets.length >= 1) {
					return;
				}
				if (e.evt.button === 0) {
					makePolygon();
				}
			}}
			onMouseMove={handleMouseMove}
		>
			<RasterImage />
		</Group>
	);
}

