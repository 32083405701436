import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import CustomToggle from 'components/Basic/CustomToggle/CustomToggle';
import { FlexDivRow } from './style';
import { toggleNoGapGroundMount } from 'store/slices/ToolSlice';
export default memo(function NoGapGroundMountToggle() {
	const { noGapGroundMountEnabled, newlyCreatedFacets } = useSelector((state: RootState) => state.toolNewPostions.data);
	console.log(noGapGroundMountEnabled);
	const dispatch = useDispatch<AppDispatch>();
	const toggleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(toggleNoGapGroundMount({ noGapGroundMountEnabled: event.target.checked}));
	};
	const disabled = !!(Object.keys(newlyCreatedFacets).length);
	return (
		<FlexDivRow>
			<CustomToggle
				title='Ground Mount'
				checked={noGapGroundMountEnabled}
				onChange={toggleChecked}
				disabled={disabled}
			/>
		</FlexDivRow>
	);
});