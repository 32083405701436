import ConfirmationModal from 'components/Basic/Modal/Confirmation';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleRecalculateLayoutModalState } from 'store/slices/EditFacetModalSlice';
import { recalculateFacetLayoutRequest } from '../ButtonDiv/Facet/RecalculateLayoutBtn/api';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export default function RecalculateFacetLayoutModal() {
	const dispatch = useDispatch<AppDispatch>();
	const { recalculateLayoutModalOpen } = useSelector((state: RootState) => state.editFacetModal.data);
	const { uuid, panel: {key: panelKey}, imagerySource } = useSelector((state: RootState) => state.roofData.data);
	const { selectedRoofIndex } = useSelector((state: RootState) => state.toolNewPostions.data);

	const recalculateLayoutQuery = useMutation(async () => {
		if (!selectedRoofIndex) throw new Error('E_NO_SEGMENT_SELETED');
		const payload = {
			designUuid: uuid,
			panelKey,
			segmentIds: [selectedRoofIndex]
		};
		await recalculateFacetLayoutRequest(payload);
		await dispatch(changePanel({ uuid, panelKey, source: imagerySource }));
	}, {
		mutationKey: 'recalculate-layout',
		onError: (error: AxiosError<{ message: string }>) => {
			if (error.response?.data) {
				toast.error(error.response.data?.message || error.message);
			}
		},
	});

	function closeModal() {
		dispatch(toggleRecalculateLayoutModalState({ value: false }));
	}

	function hanldeConfirmation() {
		closeModal();
		recalculateLayoutQuery.mutate();
	}

	return (
		<ConfirmationModal 
			message= "Are you sure you want to recalculate the panel layout of this facet?"
			onConfirm={hanldeConfirmation} 
			open={recalculateLayoutModalOpen} 
			onCancel={()=>closeModal()}/>
	);
}

