import RedoButton from './redoBtn';
import { StripDiv, LabelInfo, CancelModal, BtnWrap } from './style';
import UndoButton from './undoBtn';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import CancelBtn from '../../Panel/CancelBtn';

export default function DeleteObstructionModeStrip(){
	const { 
		facetHistory:{ history, currentIndex }, deleteObstructionsMode, deletedObstructionIDs
	} = useSelector((state: RootState) => state.toolNewPostions.data);
	const showDeleteOBstructionUndoRedo = (deleteObstructionsMode && (!!deletedObstructionIDs?.length || !!history?.length));
	const shouldDisable = (currentIndex >= history.length - 1);
	return (
		<StripDiv>
			<LabelInfo>
				<svg width="22" height="22" 
					viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M16.4313 1L16.4313 0.2L16.4313 1ZM12.1293 1V0.2V1ZM4.92797 1L4.92797 1.8L4.92797 1ZM1 
					5.09352H0.2H1ZM1 14.8882L0.2 14.8882L1 14.8882ZM5.23972 1L5.23972 0.2L5.23972 1ZM16.9701 12.7303L17.1021 
					13.5194L17.1021 13.5194L16.9701 12.7303ZM12.8894 16.2996L13.6536 16.536L13.6536 16.536L12.8894 16.2996ZM20.0787 
					13.1727L19.7303 13.8929L19.7303 13.8929L20.0787 13.1727ZM16.4047 15.2733C16.0923 14.9609 15.5857 
					14.9609 15.2733 15.2733C14.9609 15.5857 14.9609 16.0923 15.2733 16.4047L16.4047 15.2733ZM18.9831 20.1145C19.2955 
					20.4269 19.802 20.4269 20.1145 20.1145C20.4269 19.802 20.4269 19.2955 20.1145 18.9831L18.9831 20.1145ZM15.2733 
					18.9831C14.9609 19.2955 14.9609 19.802 15.2733 20.1145C15.5857 20.4269 16.0923 20.4269 16.4047 
					20.1145L15.2733 18.9831ZM20.1145 16.4047C20.4269 16.0923 20.4269 15.5857 20.1145 15.2733C19.802 14.9609 19.2955 
					14.9609 18.9831 15.2733L20.1145 16.4047ZM17.0787 1L17.0787 1.8L17.0787 1ZM19.2787 4V4.6474H20.8787V4H19.2787ZM16.4313 
					1.8L17.0787 1.8L17.0787 0.2L16.4313 0.2L16.4313 1.8ZM19.2787 4.6474V8.29481H20.8787V4.6474H19.2787ZM12.1293 
					1.8L16.4313 1.8L16.4313 0.2L12.1293 0.2L12.1293 1.8ZM19.2787 8.29481V8.94947H20.8787V8.29481H19.2787ZM20.6444 
					8.38379L12.6949 0.434315L11.5636 1.56569L19.513 9.51516L20.6444 8.38379ZM4 1.8H4.92797V0.2H4V1.8ZM1.8 5.09352L1.8 
					4L0.2 4L0.2 5.09352L1.8 5.09352ZM1.8 12.1293V5.09352H0.2V12.1293H1.8ZM9.51516 19.513L1.56569 11.5636L0.434315 
					12.6949L8.38379 20.6444L9.51516 19.513ZM8.94947 19.2787H6.19054V20.8787H8.94947V19.2787ZM6.19054 
					19.2787H4V20.8787H6.19054V19.2787ZM1.8 17.0787L1.8 14.8882L0.2 14.8882L0.2 17.0787L1.8 17.0787ZM1.8 
					14.8882V12.1293H0.2V14.8882H1.8ZM4.92797 1.8L5.23972 1.8L5.23972 0.2L4.92797 0.2L4.92797 1.8ZM5.23972 
					1.8H12.1293V0.2H5.23972V1.8ZM22.2 17.8296C22.2 20.2433 20.2433 22.2 17.8296 22.2V23.8C21.127 23.8 23.8 21.127 23.8 
					17.8296H22.2ZM17.8296 22.2C15.4159 22.2 13.4592 20.2433 13.4592 17.8296H11.8592C11.8592 21.127 14.5323 23.8 
						17.8296 23.8V22.2ZM17.1021 13.5194C17.3382 13.4799 17.5812 13.4592 17.8296 13.4592V11.8592C17.4924 11.8592 17.161 
						11.8873 16.838 11.9413L17.1021 13.5194ZM17.5357 12.1647L5.8054 0.434315L4.67403 1.56569L16.4044 13.296L17.5357 
						12.1647ZM13.4592 17.8296C13.4592 17.378 13.5275 16.9438 13.6536 16.536L12.1251 16.0631C11.9521 16.6224 11.8592 
						17.2159 11.8592 17.8296H13.4592ZM13.6536 16.536C14.1354 14.9785 15.4661 13.7931 17.1021 13.5194L16.838 11.9413C14.5981 
						12.3161 12.7834 13.9351 12.1251 16.0631L13.6536 16.536ZM13.4381 15.7174L1.54871 4.51135L0.451291 5.67568L12.3406 
						16.8817L13.4381 15.7174ZM12.9242 19.2787H8.94947V20.8787H12.9242V19.2787ZM17.8296 13.4592C18.5124 13.4592 19.1566 
						13.6153 19.7303 13.8929L20.4272 12.4526C19.6409 12.0721 18.759 11.8592 17.8296 11.8592V13.4592ZM19.7303 13.8929C21.1936 
						14.6009 22.2 16.0985 22.2 17.8296H23.8C23.8 15.4619 22.4217 13.4176 20.4272 12.4526L19.7303 
						13.8929ZM19.2787 8.94947V13.1727H20.8787V8.94947H19.2787ZM15.2733 16.4047L17.1282 18.2596L18.2596 17.1282L16.4047 
						15.2733L15.2733 16.4047ZM17.1282 18.2596L18.9831 20.1145L20.1145 18.9831L18.2596 17.1282L17.1282 18.2596ZM16.4047 
						20.1145L18.2596 18.2596L17.1282 17.1282L15.2733 18.9831L16.4047 20.1145ZM18.9831 15.2733L17.1282 17.1282L18.2596 
						18.2596L20.1145 16.4047L18.9831 15.2733ZM4 19.2787C2.78497 19.2787 1.8 18.2938 1.8 17.0787L0.2 17.0787C0.2 19.1774 
						1.90132 20.8787 4 20.8787V19.2787ZM20.8787 4C20.8787 1.90132 19.1774 0.2 17.0787 0.2L17.0787 1.8C18.2938 1.8 19.2787 
						2.78497 19.2787 4H20.8787ZM4 0.2C1.90132 0.2 0.2 1.90132 0.2 4L1.8 4C1.8 2.78497 2.78497 1.8 4 1.8V0.2Z" fill='#64686A' />
					<path d="M16.4313 1L16.4313 0.2L16.4313 1ZM12.1293 1V0.2V1ZM4.92797 1L4.92797 1.8L4.92797 1ZM1 5.09352H0.2H1ZM1 14.8882L0.2 
					14.8882L1 14.8882ZM5.23972 1L5.23972 0.2L5.23972 1ZM16.9701 12.7303L17.1021 13.5194L17.1021 13.5194L16.9701 12.7303ZM12.8894 
					16.2996L13.6536 16.536L13.6536 16.536L12.8894 16.2996ZM20.0787 13.1727L19.7303 13.8929L19.7303 13.8929L20.0787 13.1727ZM16.4047 
						15.2733C16.0923 14.9609 15.5857 14.9609 15.2733 15.2733C14.9609 15.5857 14.9609 16.0923 15.2733 16.4047L16.4047 
						15.2733ZM18.9831 20.1145C19.2955 20.4269 19.802 20.4269 20.1145 20.1145C20.4269 19.802 20.4269 19.2955 20.1145 
						18.9831L18.9831 20.1145ZM15.2733 18.9831C14.9609 19.2955 14.9609 19.802 15.2733 20.1145C15.5857 20.4269 16.0923 20.4269 
						16.4047 20.1145L15.2733 18.9831ZM20.1145 16.4047C20.4269 16.0923 20.4269 15.5857 20.1145 15.2733C19.802 14.9609 19.2955 
						14.9609 18.9831 15.2733L20.1145 16.4047ZM17.0787 1L17.0787 1.8L17.0787 1ZM19.2787 4V4.6474H20.8787V4H19.2787ZM16.4313 
						1.8L17.0787 1.8L17.0787 0.2L16.4313 0.2L16.4313 1.8ZM19.2787 4.6474V8.29481H20.8787V4.6474H19.2787ZM12.1293 1.8L16.4313 
						1.8L16.4313 0.2L12.1293 0.2L12.1293 1.8ZM19.2787 8.29481V8.94947H20.8787V8.29481H19.2787ZM20.6444 
						8.38379L12.6949 0.434315L11.5636 1.56569L19.513 9.51516L20.6444 8.38379ZM4 1.8H4.92797V0.2H4V1.8ZM1.8 5.09352L1.8 4L0.2 
						4L0.2 5.09352L1.8 5.09352ZM1.8 12.1293V5.09352H0.2V12.1293H1.8ZM9.51516 19.513L1.56569 11.5636L0.434315 
						12.6949L8.38379 20.6444L9.51516 19.513ZM8.94947 19.2787H6.19054V20.8787H8.94947V19.2787ZM6.19054 
						19.2787H4V20.8787H6.19054V19.2787ZM1.8 17.0787L1.8 14.8882L0.2 14.8882L0.2 17.0787L1.8 17.0787ZM1.8 
						14.8882V12.1293H0.2V14.8882H1.8ZM4.92797 1.8L5.23972 1.8L5.23972 0.2L4.92797 0.2L4.92797 1.8ZM5.23972 
						1.8H12.1293V0.2H5.23972V1.8ZM22.2 17.8296C22.2 20.2433 20.2433 22.2 17.8296 22.2V23.8C21.127 23.8 23.8 21.127 23.8 
						17.8296H22.2ZM17.8296 22.2C15.4159 22.2 13.4592 20.2433 13.4592 17.8296H11.8592C11.8592 21.127 14.5323 
						23.8 17.8296 23.8V22.2ZM17.1021 13.5194C17.3382 13.4799 17.5812 13.4592 17.8296 13.4592V11.8592C17.4924 11.8592 
						17.161 11.8873 16.838 11.9413L17.1021 13.5194ZM17.5357 12.1647L5.8054 0.434315L4.67403 
						1.56569L16.4044 13.296L17.5357 12.1647ZM13.4592 17.8296C13.4592 17.378 13.5275 16.9438 13.6536 16.536L12.1251 
						16.0631C11.9521 16.6224 11.8592 17.2159 11.8592 17.8296H13.4592ZM13.6536 16.536C14.1354 14.9785 15.4661 13.7931 
						17.1021 13.5194L16.838 11.9413C14.5981 12.3161 12.7834 13.9351 12.1251 16.0631L13.6536 16.536ZM13.4381 15.7174L1.54871 
						4.51135L0.451291 5.67568L12.3406 16.8817L13.4381 15.7174ZM12.9242 19.2787H8.94947V20.8787H12.9242V19.2787ZM17.8296 
						13.4592C18.5124 13.4592 19.1566 13.6153 19.7303 13.8929L20.4272 12.4526C19.6409 12.0721 18.759 11.8592 17.8296 
						11.8592V13.4592ZM19.7303 13.8929C21.1936 14.6009 22.2 16.0985 22.2 17.8296H23.8C23.8 15.4619 22.4217 13.4176 20.4272 
						12.4526L19.7303 13.8929ZM19.2787 8.94947V13.1727H20.8787V8.94947H19.2787ZM15.2733 16.4047L17.1282 18.2596L18.2596 
						17.1282L16.4047 15.2733L15.2733 16.4047ZM17.1282 18.2596L18.9831 20.1145L20.1145 18.9831L18.2596 17.1282L17.1282 
						18.2596ZM16.4047 20.1145L18.2596 18.2596L17.1282 17.1282L15.2733 18.9831L16.4047 20.1145ZM18.9831 15.2733L17.1282 
						17.1282L18.2596 18.2596L20.1145 16.4047L18.9831 15.2733ZM4 19.2787C2.78497 19.2787 1.8 18.2938 1.8 17.0787L0.2 17.0787C0.2 
						19.1774 1.90132 20.8787 4 20.8787V19.2787ZM20.8787 4C20.8787 1.90132 19.1774 0.2 17.0787 0.2L17.0787 1.8C18.2938 1.8 
						19.2787 2.78497 19.2787 4H20.8787ZM4 0.2C1.90132 0.2 0.2 1.90132 0.2 4L1.8 4C1.8 2.78497 2.78497 1.8 4 1.8V0.2Z" 
					fill="#64686A" />
				</svg>
				<label>Delete Obstructions</label>
			</LabelInfo>
			{showDeleteOBstructionUndoRedo &&
				<BtnWrap>
					<UndoButton />
					{/* {!shouldDisable && */}
					<RedoButton />
					{/* } */}
				</BtnWrap>
			}
			<CancelBtn strip={true} />
		</StripDiv>
	);
}