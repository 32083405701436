import { Image } from 'react-konva';
import useImage from 'use-image';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useKonvaImageDimensions } from 'contexts/ToolDimensionsAndScaleContext';

export default function RasterImage() {

	const { jpgUrl } = useSelector((state: RootState) => state.roofData.data);
	const [image] = useImage(jpgUrl, 'anonymous', 'origin');
	const imgDims = useKonvaImageDimensions();

	return (
		<Image
			image={image}
			preventDefault={false}
			height={imgDims.height}
			width={imgDims.width}
		/>
	);
}