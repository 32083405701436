import { PANEL_OFF_COLOR, PANEL_OVERLAP_THRESHOLD } from '../../../constants';
import { Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { getPanelPointsFromConfig, getSnappedPosition, getRotatedLinePoints, checkIntersection, isOverLap } from '../PanelUtil';
import { useRefDispatch, useRefs } from 'contexts/RefContext';
import Konva from 'konva';
import { setNewlyAddedPanel } from 'store/slices/PanelSlice';
import { getAbsolutePoints } from 'components/DisplayEnergy/util';
import { ORIENTATION } from 'store/slices/AddPanelModalSlice/types';
import { KonvaEventObject } from 'konva/lib/Node';
import { KonvaGroupNames } from '../utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

const CustomPanel = () => {
	const scale = useKonvaImageScale();
	const { panel, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { orientation: panelOrientation } = useSelector((state: RootState) => state.addPanelModal.data);
	const { moveStage } = useSelector((state: RootState) => state.toolNewPostions.data);

	const { konvaRef } = useRefs();
	const movablePanelRef = useRef<Konva.Line | null>(null);
	const intersectedRef = useRef<Konva.Line | null>(null);
	const refDispatch = useRefDispatch();
	const dispatch = useDispatch();

	useEffect(() => {
		refDispatch({ type: 'ADD_PANEL_REF', payload: { ref: movablePanelRef } });
	}, [refDispatch]);

	const panelPoints = useMemo(() => {
		let orientation: RasterRoofSegment['orientation'];
		if (panelOrientation === ORIENTATION.DEFAULT) {
			orientation = 'LANDSCAPE';
		} else if (panelOrientation === ORIENTATION.LANDSCAPE) {
			orientation = 'LANDSCAPE';
		} else {
			orientation = 'POTRAITT';
		}
		const width = panel.width;
		const height = panel.height;
		if (!konvaRef?.current) return [];
		return getPanelPointsFromConfig({ orientation, width, height }, scale);
	}, [panelOrientation, panel, konvaRef, scale]);

	const updatePanelPositionAndRotation = useCallback((e: KonvaEventObject<DragEvent>) => {
		if (!konvaRef?.current || !movablePanelRef?.current) return;
		intersectedRef.current = null;

		const targetFacets = konvaRef.current?.find(`.${KonvaGroupNames.existingFacet}`) as Konva.Line[];
		const intersectedFacet = checkIntersection(targetFacets, movablePanelRef?.current as Konva.Line);

		if (intersectedFacet) {
			intersectedRef.current = intersectedFacet;
			const matchingRoofSeg = allRoofSegs.find(seg => seg.id === intersectedFacet.id());
			if (matchingRoofSeg) {
				const parentGroup = intersectedFacet.getParent();
				const panelGroup = parentGroup?.findOne(`.${KonvaGroupNames.existingPanelsGroup}`) as Konva.Group | null;
				const panels = (panelGroup?.children || []) as Konva.Line[];
				
				const dims = {
					width: matchingRoofSeg.panelWidth * scale.x,
					height: matchingRoofSeg.panelHeight * Math.cos(matchingRoofSeg.pitchDegrees * (Math.PI / 180)) * scale.y
				};
				const width = dims.width, height = dims.height;
				let rotationAngle = matchingRoofSeg.azimuthDegrees;

				// const panelWidth = panels[0].getWidth(), panelHeight = panels[0].getHeight();
				const chnageOrientation = (matchingRoofSeg.orientation === 'POTRAITT' && panelOrientation === ORIENTATION.LANDSCAPE) ||
				(matchingRoofSeg.orientation === 'LANDSCAPE' && panelOrientation === ORIENTATION.PORTRAIT);

				if (chnageOrientation) {
					rotationAngle+=90;
				}
				rotationAngle %= 360;

				if (!movablePanelRef?.current) return;

				const points = getRotatedLinePoints(e.target.getRelativePointerPosition(), width, height, rotationAngle);
				movablePanelRef?.current.points(points);
				const { hasOverlap } = isOverLap(movablePanelRef.current, panels, PANEL_OVERLAP_THRESHOLD);
				const strokeColor = hasOverlap? 'red' : 'black';
				movablePanelRef.current.attrs.stroke = strokeColor;
				
				const closestEdge = getSnappedPosition(movablePanelRef.current.position(), movablePanelRef?.current, panels);

				if (closestEdge) movablePanelRef?.current.position(closestEdge);
			}
		} else {
			movablePanelRef.current.points(panelPoints);
			movablePanelRef.current.stroke('black');
		}
	}, [konvaRef, allRoofSegs, scale, panelOrientation, panelPoints, movablePanelRef]);

	function insrtToIntersectedFacet() {
		//check for overlap if red means do not allow to place 
		if(movablePanelRef?.current?.attrs.stroke === 'red') return;

		const intersectedFacet = intersectedRef.current;
		const panelToInsert = movablePanelRef?.current;
		if (!intersectedFacet || !panelToInsert || !konvaRef?.current) return;

		const matchingRoofSeg = allRoofSegs.find(seg => seg.id === intersectedFacet.id());
		if (!matchingRoofSeg) return;
		const exteriorCoords: number[][] = [];
		const coords = getAbsolutePoints(panelToInsert, scale, konvaRef?.current?.position());
		for (let i = 0; i < coords.length; i += 2) {
			exteriorCoords.push([coords[i], coords[i + 1]]);
		}
		const shouldClosePolygon = exteriorCoords.length === 4; // since panels are rect;
		if (shouldClosePolygon) exteriorCoords.push(exteriorCoords[0]);

		panelToInsert.visible(false);
		intersectedRef.current = null;

		dispatch(setNewlyAddedPanel({
			addedPanels: [{
				exteriorCoords,
				orientation: panelOrientation,
				roofSegmentId: matchingRoofSeg.id,
			}]
		}));
		intersectedRef.current = null;
	}

	return (
		<Line
			visible={false}
			ref={movablePanelRef}
			name="movable_panel"
			fill={PANEL_OFF_COLOR}
			listening={true}
			strokeWidth={1.4}
			stroke={'black'}
			points={panelPoints}
			draggable={!moveStage}
			closed={true}
			onDragStart={()=> {
				intersectedRef.current = null;
				movablePanelRef.current?.stroke('black');
			}}
			onDragMove={(e) => updatePanelPositionAndRotation(e)}
			onDragEnd={() => insrtToIntersectedFacet()}
		/>
	);
};

export default memo(CustomPanel);
