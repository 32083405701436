import Popup from 'reactjs-popup';
import styled from 'styled-components';

export const SettingsPopup = styled(Popup)`
  &-content {
    max-width: 700px;
    border-radius: 4px;
    margin-top: 100px !important;
		width:90%;
  }
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px 16px;

	&.confirmation-modal{
		padding:1rem 1rem .5rem;
	}
`;

export const ModalHeader = styled.h4`
	color: var(--highlight);
	align-self: flex-start;
  margin-top: 0px;
  padding: 2px 0px;
	margin-bottom:6px;
	font-size: 1.25rem;

	&.confirmation-modal-head{
	font-size: 1.05rem;
	margin-bottom: 8px;
	color: var(--highlight);
	}
`;
export const ModalContent = styled.div`
	font-family: Inter;
	font-size: 0.95rem;
	line-height: 1.42;
	display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 0px 0px 10px;
	color: var(--label);
	// align-items: center;
  // justify-content: center;

`;

export const ModalFooter = styled.div`
	display: flex;
	align-self: flex-end;
	justify-content: flex-end;
  margin-top: 10px;
	bottom: 5px;
	flex-direction: row;
	gap: 15px;
	padding-bottom: 20px;
	padding-right: 40px;
	width: 100%;

	button{
			border: 1px solid #bdbdbd;
			padding: 10px 22px;
			border-radius: 3px;
	}
			&.confirmation-modal-footer{
				justify-content: center;
				padding-right: 0;
				margin-top:20px;
			}
`;

export const ConfirmationPopup = styled(Popup)`
  &-content {
    max-width: 600px;
    border-radius: 4px;
  	margin-top: 120px !important;
  }
`;
