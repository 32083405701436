import { useSelector } from 'react-redux';
import { RootState } from 'store';
import ModeStrip from '.';
import DeleteObstructionModeStrip from '../ButtonDiv/Facet/DeleteObstructionBtn/strip';
import DrawModeStrip from '../ButtonDiv/Facet/DrawBtn/strip';
import AddPanelModeStrip from '../ButtonDiv/Panel/AddPanelButton/strip';
import DeletePanelStrip from '../ButtonDiv/Panel/DeletePanelBtn/strip';
import MovePanelStrip from '../ButtonDiv/Panel/MovePanelButton/strip';
import ShowCurrentAzimuth from '../ButtonDiv/Facet/ShowCurrentAzimuth';

export default function ModeStripContainer() {
	const { enableAddPanel, deleteIndividualPanelMode, enablePanelMove } = useSelector((state: RootState) => state.panelSlice.data);
	const { drawModeEnabled, deleteObstructionsMode, selectedRoofIndex, 
		selectedRoofAzimuth } = useSelector((state: RootState) => state.toolNewPostions.data);

	return (
		<>
			{drawModeEnabled && <ModeStrip><DrawModeStrip /></ModeStrip>}
			{deleteObstructionsMode && <ModeStrip><DeleteObstructionModeStrip /></ModeStrip>}
			{enableAddPanel && <ModeStrip><AddPanelModeStrip /></ModeStrip>}
			{deleteIndividualPanelMode && <ModeStrip><DeletePanelStrip /> </ModeStrip>}
			{enablePanelMove && <ModeStrip><MovePanelStrip /></ModeStrip>}
			{(selectedRoofIndex && selectedRoofAzimuth!=null) && <ModeStrip><ShowCurrentAzimuth /></ModeStrip>}
		</>
	);
}