import Konva from 'konva';
import { createContext, useContext, ReactNode, useReducer, MutableRefObject, } from 'react';

type RefsState = {
	sliderRef: MutableRefObject<HTMLInputElement | null> | null;
	konvaRef: MutableRefObject<Konva.Stage | null> | null
	movablePanelRef: MutableRefObject<Konva.Line | null> | null;
}

type RefPayload = {
	ref: RefsState['sliderRef'] | RefsState['konvaRef'] | RefsState['movablePanelRef'];
}

type ActionType = 'ADD_SLIDER_REF' | 'ADD_KONVA_REF' | 'ADD_LAST_POLYGON_REF' | 'ADD_PANEL_REF';

type Action = { type: ActionType, payload: RefPayload };

function RefsReducer(refsState: RefsState, action: Action): RefsState {
	const { ref } = action.payload;
	switch (action.type) {
	case 'ADD_SLIDER_REF': {
		return {
			...refsState,
			sliderRef: ref as unknown as RefsState['sliderRef']
		};
	}
	case 'ADD_KONVA_REF': {
		if (!ref) return refsState;
		return {
			...refsState,
			konvaRef: ref as unknown as RefsState['konvaRef']
		};
	}
	case 'ADD_PANEL_REF': {
		return {
			...refsState,
			movablePanelRef: ref as unknown as RefsState['movablePanelRef']
		};
	}
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}

const initialState: RefsState = {
	sliderRef: null,
	konvaRef: null,
	movablePanelRef: null,
};

const RefContext = createContext<RefsState | undefined>(undefined);
const RefDispatchContext = createContext<React.Dispatch<Action> | undefined>(undefined);

export function RefProvider(props: { children: ReactNode }) {
	const [state, dispatch] = useReducer(RefsReducer, initialState);

	return (
		<RefContext.Provider value={state}>
			<RefDispatchContext.Provider value={dispatch}>
				{props.children}
			</RefDispatchContext.Provider>
		</RefContext.Provider>
	);
}

export function useRefs() {
	const context = useContext(RefContext);
	if (context === undefined) {
		throw new Error('useRefs must be used within a ToolProvider');
	}
	return context;
}

export function useRefDispatch() {
	const context = useContext(RefDispatchContext);
	if (context === undefined) {
		throw new Error('useRefDispatch must be used within a ToolProvider');
	}
	return context;
}

RefContext.displayName = 'RefContext';