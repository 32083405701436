import styled, { Interpolation } from 'styled-components';

type props = {
  styles?: Interpolation<React.CSSProperties>
	fullScreenEnabled?:boolean;
	isParentSQ?: boolean;
}
export const RightSidePanel = styled.div<props>`
		background: #fff;
		height: auto;
		padding: 0 0rem 1rem 1.75rem;
		text-align: left;
		border-radius: 8px;
		@media (max-width: 935px){
			margin-left:0;
			background: #f3f5f8;
				padding:0px;
				margin:${(props) => (props.isParentSQ ? '0 10px 15px 10px' : '0px')};
				border-radius:${(props) => (props.isParentSQ ? '0px' : '8px')};
		}
		@media (max-width: 980px){
			.fullScreenView{
				background: #f3f5f8;
				padding:0px
				
			}
			
		}

		.save-btn{
			width: 100%;
			font-size: 1.125rem;
			line-height: 1.4;
			cursor:pointer!important;
			padding: 0.5em 1.85rem;
			margin:0;
			display:flex;
			justify-content:center;
			align-items:center;
			gap:0.5em;
			margin-top:20px;
			color: #2E384D;
			width: 100% !important;
			padding: 0.5em 2rem 0.5em;
			svg{
				position: absolute;
				right: 16px;
				top:15px;
			}
			@media (max-width:935px){
				max-width: 100%;
				margin:0 auto;
				width:calc(100% - 2rem) !important;
				// display:none;
				margin-top: 12px;
				// width: 96%;
				border-radius:1px
			}
			@media (max-width:500px){
					max-width: 100%;
					width: 93%;
			}
		:disabled{
			cursor: not-allowed!important;
			opacity: 50%
		}
		}
`;

export const PanelTitle = styled.div`
	width: calc(50% - 0.5rem);

	@media (max-width: 935px){
    display: inline-flex;
    flex-direction: column;
		padding-right:6px;
		box-sizing: border-box;
	}
	@media (max-width: 540px){
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap:1em;
		padding-right:0px;
  }
  @media (max-width: 935px){
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap:1em
  }

	@media (max-width: 640px){
		width: calc(49% - 0.5rem);
	}

	@media (max-width: 480px){
		width: 100%;
	}
	
		h4 {
			color: #2E384D;
			font-weight: 500;
			font-size: 0.95rem;
			margin: 0;
			white-space: nowrap;
			font-family: 'Inter';
		}

		h2 {
			color: var(--primary);
			font-size: 1.125rem;
			font-weight: 600;
			margin-top: 0.3em;
			margin-bottom:0;
			@media (max-width: 767px){
        margin-top: 0em;
      }

		}
		.primary-color{
			color: var(--primary);
		}
		.bold{
			font-weight:700;
		}
		.font-1{
			font-size:1rem;
		}
`;

export const PanelElement = styled(PanelTitle)`
		width: 100%;
`;

export const FlexDiv = styled.div<props>`
	display: flex;
	flex-direction: row;
	margin-top: .2em;
	margin-bottom: 1em;
	align-items:center;
	${({ styles }) => styles}

	// @media (max-width: 500px){
	// 	margin-bottom: 1rem;
	// }
	
	label{
		color: #223349;
		margin-right:8px;
		margin-top:0px;
		font-size: 1.05rem;
	}
`;
export const FlexDivRow = styled.div<props>`
	display: flex;
	flex-direction: row;
	justify-content:space-between;
	margin-top: .85rem;
	margin-bottom: .35rem;
	align-items:center;
	width:100%;
	gap:.3rem;
	${({ styles }) => styles}

	// @media (max-width: 500px){
	// 	margin-bottom: 1rem;
	// }
	
	label{
		color: #2E384D;
		margin-right:0px;
		margin-bottom:0px;
		font-size: 1.05rem;
		font-weight:500;
		font-family: 'Inter';
		opacity:.95
	}
`;

export const DetailDiv = styled.div`
	display : flex;
	flex-direction: column;
	flex-wrap:wrap;
	grid-template-columns:50% 50%;
	h4 {
		color: #2d384c;
		font-weight: 400;
		font-size: 1rem;
		margin: 0;
	}

	h2 {
		color: #1457a5;
		font-size: 1.5rem;
		font-weight: 600;
		margin: 6px 0px 0px;

		&.primary-color{
			color: var(--primary);
		}
	}
`;

export const PanelDetailDiv = styled.div<props>`
	display:flex;
	padding: ${(props) => (props.fullScreenEnabled ? '0px' : '1rem')};
	background-color:${(props) => (props.fullScreenEnabled ? 'transparent' : '#F5F7FF')};
	justify-content:space-between;
	gap:.75rem;

	&:not(:last-child){
		margin-bottom:0.25rem;
	}

	&.environment-details{
		h2{
			font-size:0.8rem;
		}
		h4{
			font-size:0.9rem;
		}
	}

	input {
		margin-top: 0.3em
	}

	@media (max-width: 935px){
		padding:${(props) => (props.fullScreenEnabled ? '0px' : '0.6rem 1rem')} ;
		background:${(props) => (props.fullScreenEnabled ? '#fff' : 'transparent')};
	}
	@media (max-width: 540px)	{
		padding:${(props) => (props.fullScreenEnabled ? '0px' : '0.6rem 1rem 0.25rem')} ;
	}

	@media (min-width: 936px){
		flex-wrap:wrap;
	}

	@media (max-width: 640px){
		flex-wrap:wrap;
	}

	@media (max-width: 480px){
		flex-wrap:wrap;
		flex-direction:column;
	}
	&.design-mode-panel{
		padding-bottom: 0px;
		padding-top: 1rem; 
		flex-direction: column;
		gap:.15rem;
	}	
`;

export const SQMobileViewWrap = styled.div`
display:grid;
grid-template-columns: 50% 50%;

h2 {
	@media (min-width: 935px){
			margin-top:0px
	}
	@media (max-width: 540px) {
  font-size:1rem;
  }
	margin-bottom:0px;
	margin-top:0px
 }
	@media (max-width: 769px) {
	grid-template-columns: 70% 30%;
	}
	.widthAuto{
		width:100%
	}
	@media (max-width: 540px) {
	grid-template-columns: 60% 40%;
	}
	@media (max-width: 420px) {
	display:flex;
	flex-direction:column;
	}
  .paddingcustom{
  @media (max-width: 420px) {
    padding: 0rem 1rem 1rem 1rem;
 	}
}
 
`;

export const MobileViewWrap = styled.div`
	padding-bottom: 10px;

	.annual-usage-title h4,
	.electric-bill-title h4,
	.change-panel-title h4{
		min-width: 96px;
	}
	.electric-bill-title input,
	.annual-usage-title input{
		padding: 0.574rem 0.65rem;
    line-height: 1.5;
	}
	.change-panel-wrap{
		padding: 0rem 1rem
	}	
	.bill-annual-wrap{
		margin-bottom: 0;
	}	
`;

export const DesktopViewWrap = styled.div`

	&.screeview-wrap{
		display: flex;
		flex-direction: column;
		background: #F5F7FF;
		padding: 1rem 1rem .9rem;	
		margin-bottom: .7rem;
			.panels-modes-wrap{
				> div {
						gap: .4rem;
					}
			}
		}
		&.fullscreeview-wrap{
			display: flex;
			flex-direction: column;
			margin-bottom: .5rem;

			.panels-modes-wrap{
				flex-direction: row;
				align-items:center;
				justify-content: flex-start;
				padding-left:0;
				margin-bottom: 1.5rem;	
				> div {
						flex-direction: row;
						gap: .5rem;
					}
			}
		}

`;
export const fullScreenView = styled.div`
  //  @media (min-width: 769px) {
	// 	background: #ffffffeb;
	// 	padding: 18px 20px !important;
	// 	position: fixed;
	// 	left: 1%;
	// 	max-width: 320px;
	// 	top: 13%;
  // }
`;

export const defaultScreenView = {
	// backgroundColor: 'blue',
	// color: 'black',
	// padding: '10px',
};

export const RightArrow = styled.div`
		position: absolute;
		right:20px;
		top:13px;
`;