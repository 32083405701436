import SwitchOrientation from '../../../../../SwitchOrientation';
import UndoPanelButton from '../UndoBtn';
import RedoPanelButton from '../RedoBtn';
import { StripDiv, LabelInfo } from './style';
import CancelBtn from '../CancelBtn';

export default function AddPanelModeStrip(){
	return (
		<StripDiv>
			{/* <LabelInfo>
				<svg width="20" height="21" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.80246 21.631H1V1H16.4732V7.44718" 
						stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M21.6309 16.4731L8.7365 16.4731M15.1837 10.0259L15.1837 22.9202" 
						stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				<label>Add Panel</label>
			</LabelInfo> */}
			<SwitchOrientation />
			<UndoPanelButton />
			<RedoPanelButton />
			<CancelBtn strip={true} /> 
		</StripDiv>
		
	);
}