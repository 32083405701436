import styled from 'styled-components';

export const PanelCheckbox = styled.div`
`;
export const PanelCheckboxWrap = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	color: #64686A;
	font-size: 0.875rem;
	white-space: nowrap;
	font-family:'Inter';
	font-weight: 500;
		@media (max-width: 1325px) {	
		 gap: 0.45rem;
		}
`; 
export const PanelCheckboxSvg = styled.div`
	width:19px;
	height:19px;
	border-radius:3px;
	display: flex;
	justify-content: center;
	align-items:center;
	color: #64686A;
	font-size: 0.875rem;
	flex-shrink: 0;
	position: relative;

		svg {
			width: 11.5px;
			position:absolute;
	  }
		@media (max-width: 1325px){	
			width:17px;
			height:17px;
				svg{
					width:11px;
					height: 9px;
				}
		}
`;

