import styled from 'styled-components';

export const StripDiv = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: .5rem;
`;
export const LabelInfo = styled.div`
		font-size: 0.813rem;
		color: #4A525A;
		transition-delay: 1s;
		font-family: Inter;
		font-weight:600;
		position:relative;
		border-right: 1px solid #e4e4e4;
		padding-right: .65rem;
		margin-right: 3.5px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: .5rem;
		min-height:27px;

		// background:#fff;
		// padding: .55rem .85rem;
		// border-radius:25px;
	}
`;